import React, { useState, useEffect } from 'react';
import { useSelect } from "@refinedev/mantine";
import { Card, Select, Loader, Box } from '@mantine/core';
import { supabaseClient as supabase } from "../../utility/supabaseClient";

function SettingsGeneral() {
  const [loading, setLoading] = useState(true);
  const [selectedModelFunction, setSelectedModelFunction] = useState<string | null>(null);
  const [selectedModelContent, setSelectedModelContent] = useState<string | null>(null);

  const { selectProps: modelSelectProps } = useSelect({
    resource: "options",
    filters: [
      {
        field: "parent_id",
        operator: "eq",
        value: 23,
      },
    ],
    optionLabel: "name",
    optionValue: "id",
  });

  useEffect(() => {
    // Fetch the models and current settings
    async function fetchData() {
      try {
        const { data: settings, error: settingsError } = await supabase
          .rpc('manage_company_settings',{action: 'get'});

        if (settingsError) throw settingsError;

        setSelectedModelFunction(settings.llm_model_function_calling_id);
        setSelectedModelContent(settings.llm_model_content_completion_id);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const handleUpdate = async (field: string, value: string | null) => {
    try {
      const updateData = { [field]: value };

      const { data: updatedSettings, error: updateError } = await supabase
        .rpc('manage_company_settings', {
          action: 'update',
          arr: updateData
        });

      if (updateError) throw updateError;

      if (field === "llm_model_function_calling_id") {
        setSelectedModelFunction(value);
      } else if (field === "llm_model_content_completion_id") {
        setSelectedModelContent(value);
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Card shadow="sm" style={{ overflow: 'visible', width: '100%' }}>
      <Box  sx={(theme) => ({
        display: 'block',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.colors.blue[4] : theme.colors.blue[7],
        textAlign: 'center',
        padding: theme.spacing.xl,
        borderRadius: theme.radius.md,
        })}
      ><h4>Default Models</h4>Select which models to use with functional calling and content completion</Box>
      <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
        <Select
          label="Function Calling Model"
          placeholder="Select a model"
          data={modelSelectProps.data || []}
          value={selectedModelFunction}
          onChange={(value) => handleUpdate('llm_model_function_calling_id', value)}
          style={{ flex: 1, minWidth: '200px' }}
        />
        <Select
          label="Content Completion Model"
          placeholder="Select a model"
          data={modelSelectProps.data || []}
          value={selectedModelContent}
          onChange={(value) => handleUpdate('llm_model_content_completion_id', value)}
          style={{ flex: 1, minWidth: '200px' }}
        />
      </div>
    </Card>
  );
}

export default SettingsGeneral;
