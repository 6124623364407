import React, { useState, useEffect } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import { PlusOutlined } from "@ant-design/icons";
import {
  THEME_COLOR,
  BORDER_WIDTH,
  NODE_WIDTH,
  CIRCLE_SIZE,
  MAIN_FONT_SIZE,
  SUB_FONT_SIZE,
  TEXT_GRADIENT_CONFIG,
  NODE_BACKGROUND_COLOR,
  NODE_BOX_SHADOW,
  SELECTED_NODE_BG_COLOR,
  SELECTED_NODE_BORDER_COLOR,
  SELECTED_NODE_BOX_SHADOW,
  SELECTED_NODE_BORDER_WIDTH
} from '../../../utility/constants';

const FloatNode = ({ data, id }: any) => {
  const [isFloatHovered, setIsFloatHovered] = useState(false);
  const [isEndHovered, setIsEndHovered] = useState(false);
  const { getNode } = useReactFlow();
  const node = getNode(id);
  const isSelected = node?.selected || false;

  useEffect(() => {
    if (isSelected) {
      const style = document.createElement('style');
      style.innerHTML = `
        @keyframes ripple {
          0% {
            box-shadow: 0 0 0 0 rgba(255, 0, 128, 0.2), 0 0 0 5px rgba(255, 0, 128, 0.2), 0 0 0 10px rgba(255, 0, 128, 0.2);
          }
          100% {
            box-shadow: 0 0 0 5px rgba(255, 0, 128, 0.2), 0 0 0 10px rgba(255, 0, 128, 0.2), 0 0 0 15px rgba(255, 0, 128, 0);
          }
        }
      `;
      document.head.appendChild(style);
      return () => {
        document.head.removeChild(style);
      };
    }
  }, [isSelected]);

  const handleAddNodeClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    console.log("Add node clicked", id);
    // Handle add node logic here
  };

  const nodeStyle = {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    height: "155px",
    width: NODE_WIDTH,
    cursor: "default",
    position: "relative" as "relative",
    transition: 'all 0.3s ease',
    marginTop: '-15px',

  };

  const addButtonStyle = {
    width: "30px",
    height: "30px",
    background: isFloatHovered ? THEME_COLOR : "white",
    cursor: "pointer",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    color: isFloatHovered ? "white" : THEME_COLOR,
    border: `${BORDER_WIDTH}px solid ${THEME_COLOR}`,
    transition: "all 0.3s ease",
  };

  const endNodeStyle = {
    width: CIRCLE_SIZE,
    height: CIRCLE_SIZE,
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: isEndHovered ? SELECTED_NODE_BG_COLOR : NODE_BACKGROUND_COLOR,
    boxShadow: isSelected || isEndHovered ? SELECTED_NODE_BOX_SHADOW : NODE_BOX_SHADOW,
    border: `${isSelected ? SELECTED_NODE_BORDER_WIDTH : BORDER_WIDTH}px solid ${isSelected || isEndHovered ? SELECTED_NODE_BORDER_COLOR : THEME_COLOR}`,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    transform: isSelected || isEndHovered ? "translateY(-2px)" : "none",
    animation: isSelected ? "ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite" : "none",
    zIndex: 10,
  };

  const mainLabelStyle = {
    fontWeight: 800,
    fontSize: MAIN_FONT_SIZE,
    lineHeight: 1.2,
    color: '#000',
    marginTop: '15px',
  };

  const subLabelStyle = {
    fontSize: SUB_FONT_SIZE,
    marginTop: '3px',
    background: `linear-gradient(${TEXT_GRADIENT_CONFIG.from}, ${TEXT_GRADIENT_CONFIG.to}, ${TEXT_GRADIENT_CONFIG.deg})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };

  return (
    <div style={nodeStyle}>
      <Handle
        type="target"
        position={Position.Top}
        style={{ visibility: "hidden" }}
      />
      
      {/* Float Node (Add Button) */}
      <div
        style={{
          position: "absolute",
          top: "-20px",
          height: "0px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 2,
        }}
        onMouseEnter={() => setIsFloatHovered(true)}
        onMouseLeave={() => setIsFloatHovered(false)}
      >
        <button
          style={addButtonStyle}
        
          
          data-floatnode-part="float-node"
        >
          <PlusOutlined style={{ fontSize: '16px' }} /> 
        </button>
      </div>
      
      {/* Vertical Line */}
      <div
        style={{
          position: "absolute",
          top: "-10px",
         height: "100%",
          width: `${BORDER_WIDTH * 1.2}px`,
          background: THEME_COLOR,
          zIndex: 1,
        }}
      />  
      
      {/* End Node */}
      <div
        style={{
          position: "absolute",
          bottom: "0",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          zIndex: 2,
        }}
        onMouseEnter={() => setIsEndHovered(true)}
        onMouseLeave={() => setIsEndHovered(false)}
      >
        <div style={endNodeStyle} data-floatnode-part="end-node">
          <div style={mainLabelStyle}>End</div>
          <div>AI Response</div>
        </div>
      </div>
      
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ visibility: "hidden" }}
      />
    </div>
  );
};

export default FloatNode;
