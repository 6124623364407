import _ from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { Node, getConnectedEdges, getIncomers, useReactFlow } from "reactflow";
import {
  EdgeTypes,
  NodeTypes,
  addNewConditionEdge,
  addNewEdge,
  addNewFloatNode,
  addNewNode,
  addTimerNode,
} from "../../components/thirdparty/flowbuilder/Utils";
import StepNode from "../../components/thirdparty/flowbuilder/StepNode";
import TimerNode from "../../components/thirdparty/flowbuilder/TimerNode";
import ConditionNode from "../../components/thirdparty/flowbuilder/ConditionNode";
import StartNode from "../../components/thirdparty/flowbuilder/StartNode";
import FloatNode from "../../components/thirdparty/flowbuilder/FloatNode";
import BridgeEdge from "../../components/thirdparty/flowbuilder/BridgeEdge";
import CustomEdge from "../../components/thirdparty/flowbuilder/CustomEdge";
import { GlobalContext } from "../../contexts/flowProvider";

function useFlowBuilder() {
  const { setNodes, setEdges, getNodes, getNode, getEdges } = useReactFlow();
  const { state, dispatch } = useContext<any>(GlobalContext);
  const nodes = getNodes();
  const edges = getEdges();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDrop, setSelectedDrop] = useState<any>({});
  const [currentEdge, setCurrentEdge] = useState<any>({});
  const [currentNode, setCurrentNode] = useState<any>({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [currentSideData, setCurrentSideData] = useState<any>({});
  const [onlySteps, setOnlySteps] = useState<boolean>(false);

  const nodeTypes = useMemo(
    () => ({
      StepNode: StepNode,
      TimerNode: TimerNode,
      Condition: ConditionNode,
      startNode: StartNode,
      FloatNode: FloatNode,
    }),
    []
  );
  const edgeTypes: any = useMemo(
    () => ({
      bridge: BridgeEdge,
      custom: CustomEdge,
    }),
    []
  );
  const proOptions = { hideAttribution: true };


  const handleNodeClick = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>, node: any) => {
      console.log("Node clicked:", node.type, node.id);
      
      const tagName = (event.target as HTMLElement).tagName;
      const clickedElement = event.target as HTMLElement;
      const floatNodePart = clickedElement.closest('[data-floatnode-part]')?.getAttribute('data-floatnode-part');
  
      if (tagName !== "DIV" && node.type === NodeTypes.FloatNode) {
        console.log("Clicked on FloatNode button");
        setCurrentNode(node);
        setIsModalOpen(true);
        setOnlySteps(false);
        return;
      }
  
      if (node.type === NodeTypes.TimerNode) {
        console.log("Clicked on TimerNode");
        return;
      }
  
      setSelectedDrop({});
  
      let nodeData = { ...node };
  
      if (node.type === NodeTypes.FloatNode && floatNodePart === 'end-node') {
        console.log("Clicked on FloatNode end-node part");
        nodeData.data = {
          ...nodeData.data,
          actionTitle: "end",
          label: "End the flow",
          subLabel: "AI Response",
        };
      }
  
      setCurrentSideData(nodeData);
  
      console.log("Node data being set:", nodeData);
  
      dispatch({
        type: "NODE_SIDE_BAR",
        payload: {
          open: true,
          activeId: node.id,
        },
      });
    },
    [setCurrentNode, setIsModalOpen, setOnlySteps, setSelectedDrop, setCurrentSideData, dispatch]
  );

  const stepActionHandle = (item: any) => {
    console.log("stepActionHandle called with item:", item);
    const getNewNode: any =
      item.type === NodeTypes.TimerNode ? addTimerNode() : addNewNode(item);
    if (item.type !== NodeTypes.TimerNode) {
      setCurrentSideData(getNewNode);
      dispatch({
        type: "NODE_SIDE_BAR",
        payload: {
          open: true,
          activeId: getNewNode.id,
        },
      });
    }
    const currentClickedNode = new Array(currentNode);
    const connectedEdge: any = getConnectedEdges(currentClickedNode, edges);
    const getIncomingNodes = getIncomers(currentNode, nodes, edges);
  
    console.log("Current edge:", currentEdge);
    console.log("Connected edge:", connectedEdge);
  
    const isTopmostButton = () => {
      if (!currentEdge.id) return false;
      return currentEdge.source === 'start-node' || currentEdge.source === 'multi-condition-node';
    };
  
    const topmostButtonStatus = isTopmostButton();
    console.log("Is topmost button:", topmostButtonStatus);
  
    setNodes((nodes: Node[]) => {
      let newNodes = [...nodes];
      let insertIndex;
      if (currentEdge.id) {
        insertIndex = newNodes.findIndex(node => node.id === currentEdge.target);
      } else {
        insertIndex = newNodes.findIndex(node => node.id === currentNode.id);
      }
      console.log("Insert index:", insertIndex);
      if (insertIndex !== -1) {
        newNodes.splice(insertIndex, 0, getNewNode);
      } else {
        newNodes.push(getNewNode);
      }
      return newNodes;
    });
  
    setEdges((edges) => {
      let newEdges = [...edges];
      if (currentEdge.id) {
        const sourceNode = getNode(currentEdge.source);
        const targetNode = getNode(currentEdge.target);
        console.log("Source node:", sourceNode);
        console.log("Target node:", targetNode);
  
        if (topmostButtonStatus && targetNode) {
          // For topmost button, update the existing edge and add a new one
          const updatedEdge = {
            ...currentEdge,
            target: getNewNode.id,
          };
          newEdges = newEdges.map(edge => 
            edge.id === currentEdge.id ? updatedEdge : edge
          );
  
          const newEdgeToTarget = addNewEdge(
            getNewNode.id,
            targetNode.id,
            "bridge",
            {
              ...currentEdge.data,
              condition: "",
              icon: false,
            }
          );
          // Add style to the new edge
          newEdges.push({
            ...newEdgeToTarget,
            style: {
              stroke: "#1890ff",
              strokeWidth: 3,
            },
          });
          console.log("Updated edge:", updatedEdge);
          console.log("New edge to target for topmost button:", newEdgeToTarget);
        } else if (sourceNode && targetNode) {
          // For other cases, update the existing edge and add a new one
          // Remove the old edge
          newEdges = newEdges.filter(edge => edge.id !== currentEdge.id);
  
          // Add edge from source to new node
          const newEdgeFromSource = addNewEdge(
            sourceNode.id,
            getNewNode.id,
            currentEdge.type,
            {
              ...currentEdge.data,
              condition: currentEdge.data?.condition ?? "",
              icon: currentEdge.data?.condition ? true : false,
            }
          );
          // Add style to the new edge
          newEdges.push({
            ...newEdgeFromSource,
            style: {
              stroke: "#1890ff",
              strokeWidth: 3,
            },
          });
          console.log("New edge from source:", newEdgeFromSource);
  
          // Add edge from new node to target
          const newEdgeToTarget = addNewEdge(
            getNewNode.id,
            targetNode.id,
            "bridge"  // Always create a bridge edge to ensure "add new" button
          );
          // Add style to the new edge
          newEdges.push({
            ...newEdgeToTarget,
            style: {
              stroke: "#1890ff",
              strokeWidth: 3,
            },
          });
          console.log("New edge to target:", newEdgeToTarget);
        }
      } else if (connectedEdge.length) {
        console.log("Handling end of arm edge update");
        const prevNode: any = getNode(connectedEdge[0]?.source);
        
        // Update the existing edge to point to the new node
        const updatedEdge = {
          ...connectedEdge[0],
          target: getNewNode.id,
          type: connectedEdge[0]?.data?.condition ? EdgeTypes.custom : EdgeTypes.bridge,
          data: {
            ...connectedEdge[0].data,
            condition: connectedEdge[0]?.data?.condition ?? "",
            icon: connectedEdge[0]?.data?.condition ? true : false,
          }
        };
        // Replace the old edge with the updated one
        newEdges = newEdges.map(edge => 
          edge.id === connectedEdge[0].id ? updatedEdge : edge
        );
        console.log("Updated edge:", updatedEdge);
  
        // Add a new edge from the new node to the current node (FloatNode)
        const newEdgeToFloatNode = addNewEdge(
          getNewNode.id,
          currentNode.id,
          "default"  // Changed to "default" to avoid adding a new node button
        );
        // Add style to the new edge
        newEdges.push({
          ...newEdgeToFloatNode,
          style: {
            stroke: "#1890ff",
            strokeWidth: 3,
          },
        });
        console.log("New edge to float node:", newEdgeToFloatNode);
      }
      return newEdges;
    });
  
    if (getIncomingNodes[0]?.type === NodeTypes.Condition) {
      getNewNode.data.condition = getIncomingNodes[0]?.data?.actionTitle;
      getNewNode.data.branch =
        connectedEdge[0]?.data?.condition === "Yes" ? true : false;
    }
  
    setIsModalOpen(false);
    setCurrentEdge({});
    setCurrentNode({});
  };

  const conditionActionHandle = (item: any) => {
    // Nodes actions or update
    const getNewNode = addNewNode(item);
    const getNewFloatNode1 = addNewFloatNode();
    const getNewFloatNode2 = addNewFloatNode();
    const currentClickedNode = new Array(currentNode);
    const connectedEdge: any = getConnectedEdges(currentClickedNode, edges);
    const prevNode: any = getNode(connectedEdge[0]?.source);
    

    setNodes((nodes: any) => {
      const nodesCopy = nodes.filter((item: any) => item.id !== currentNode.id);
      const newNodes = [
        ...nodesCopy,
        getNewNode,
        getNewFloatNode1,
        getNewFloatNode2,
      ];
      return newNodes;
    });

    if (prevNode.type === NodeTypes.Condition) {
      const standAloneEdge = _.cloneDeep(connectedEdge[0]);
      standAloneEdge.target = getNewNode.id;
      standAloneEdge.source = prevNode.id;
      standAloneEdge.type = EdgeTypes.custom;
      standAloneEdge.data.condition = connectedEdge[0]?.data.condition;
      standAloneEdge.data.icon = true;
      setEdges((edges) => {
        const edgesCopy = edges.filter(
          (item) => item.id !== connectedEdge[0].id
        );
        const newlyBindedNoFloatEdge = addNewConditionEdge(
          getNewNode.id,
          getNewFloatNode2.id,
          "No",
          false
        );
        const newlyBindedYesFloatEdge = addNewConditionEdge(
          getNewNode.id,
          getNewFloatNode1.id,
          "Yes",
          false
        );
        edgesCopy.push(standAloneEdge);
        edgesCopy.push(newlyBindedYesFloatEdge);
        edgesCopy.push(newlyBindedNoFloatEdge);
        return edgesCopy;
      });
    } else {
      const standAloneEdge = _.cloneDeep(connectedEdge[0]);
      standAloneEdge.target = getNewNode.id;
      standAloneEdge.source = prevNode.id;
      standAloneEdge.type = "bridge";
      setEdges((edges) => {
        const edgesCopy = edges.filter(
          (item) => item.id !== connectedEdge[0].id
        );
        const newlyBindedNoFloatEdge = addNewConditionEdge(
          getNewNode.id,
          getNewFloatNode2.id,
          "No",
          false
        );
        const newlyBindedYesFloatEdge = addNewConditionEdge(
          getNewNode.id,
          getNewFloatNode1.id,
          "Yes",
          false
        );
        edgesCopy.push(standAloneEdge);
        edgesCopy.push(newlyBindedYesFloatEdge);
        edgesCopy.push(newlyBindedNoFloatEdge);
        return edgesCopy;
      });
    }
    setCurrentEdge({});
    setCurrentNode({});
    setIsModalOpen(false);
    setSelectedIndex(0);
  };
  return {
    isModalOpen,
    currentEdge,
    currentNode,
    currentSideData,
    selectedIndex,
    nodeTypes,
    edgeTypes,
    onlySteps,
    proOptions,
    selectedDrop,
    setSelectedDrop,
    setOnlySteps,
    handleNodeClick,
    stepActionHandle,
    setCurrentNode,
    setCurrentEdge,
    setIsModalOpen,
    setCurrentSideData,
    setSelectedIndex,
    conditionActionHandle,
  };
}

export default useFlowBuilder;