import React, { useState, useMemo } from 'react';
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from "reactflow";
import { PlusOutlined } from '@ant-design/icons';
import { THEME_COLOR, BORDER_WIDTH } from '../../../utility/constants';

const BridgeEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
  onAddNode,
}: any) => {
  const [isHovered, setIsHovered] = useState(false);

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY: sourceY - 4, // Slightly adjust the source Y position
    sourcePosition,
    targetX,
    targetY: targetY + 4, // Slightly adjust the target Y position
    targetPosition,
  });

  const buttonPosition = useMemo(() => {
    const isFirstVerticalEdge = id.startsWith("start-to-");
    const midX = targetX;
    const midY = isFirstVerticalEdge ? sourceY + 105 : (sourceY + targetY) / 2;

    return { x: midX, y: midY };
  }, [id, sourceX, sourceY, targetX, targetY]);

  const handleButtonClick = (event: React.MouseEvent) => {
    if (onAddNode) {
      onAddNode(id, buttonPosition.x, buttonPosition.y);
    }
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={{
        ...style,
        strokeWidth: BORDER_WIDTH,
        stroke: THEME_COLOR,
      }} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${buttonPosition.x}px,${buttonPosition.y}px)`,
            pointerEvents: "all",
            zIndex: 100,
          }}
          className="nodrag nopan"
        >
          <button
            style={{
              width: "25px",
              height: "25px",
              background: isHovered ? THEME_COLOR : "white",
              cursor: "pointer",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: isHovered ? "white" : THEME_COLOR,
              border: `${BORDER_WIDTH}px solid ${THEME_COLOR}`,
              transition: "all 0.3s ease",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleButtonClick}
          >
            <PlusOutlined style={{ fontSize: '12px' }} />
          </button>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default BridgeEdge;