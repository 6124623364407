import React, { useContext, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import ReactFlow, {
  Controls,
  Edge,
  Panel,
  getRectOfNodes,
  useEdgesState,
  useNodesState,
  useReactFlow,
  Node,
  Position,
  Background, BackgroundVariant 
} from "reactflow";
import "reactflow/dist/style.css";
import useFlowBuilder from "../../../../hooks/reactflow/useFlowBuilder";
import useElementSize from "../../../../hooks/reactflow/useElementSize";
import { toJSON } from "../../../thirdparty/flowbuilder/Utils";
import { getLayoutedElements } from "../../../thirdparty/flowbuilder/WorkflowLayoutUtils";
import SideBar from "../../../thirdparty/flowbuilder/sideBar";
import SelectNodeModal from "../../../thirdparty/flowbuilder/SelectNodeModal";
import NodeSelectTab from "../../../thirdparty/flowbuilder/NodeSelectTab";
import { GlobalContext } from "../../../../contexts/flowProvider";
import { useUpdate, useList, BaseKey } from "@refinedev/core";
import { THEME_COLOR, BORDER_WIDTH } from '../../../../utility/constants';
import "../../../thirdparty/flowbuilder/style.css";
import { ISkills } from "../../../../interfaces";

interface Skill {
  keyword: string;
  id: BaseKey;
  name: string;
}

interface AppFlowBuilderComponentProps {
  name: string;
  id: string;
  json_store: string | null;
  skills?: ISkills[];
}

interface CustomNode extends Node {
  positionAbsolute?: { x: number; y: number };
}

interface SkillData {
  keyword: string;
  id: BaseKey;
  name: string;
  data?: any;
  app_id?: string;
  status_id?: number;
}

const AppFlowBuilderComponent: React.FC<AppFlowBuilderComponentProps> = ({
  name,
  id,
  json_store,
  skills,
}: AppFlowBuilderComponentProps) => {
  const { mutate } = useUpdate();
  const { state, dispatch } = useContext<any>(GlobalContext);
  const { id: appId } = useParams<{ id: string }>();
  const {
    isModalOpen,
    currentSideData,
    selectedIndex,
    nodeTypes,
    edgeTypes,
    onlySteps,
    proOptions,
    stepActionHandle,
    setIsModalOpen,
    setCurrentEdge,
    setSelectedIndex,
    conditionActionHandle,
    setOnlySteps,
    handleNodeClick,
    setCurrentSideData,
  } = useFlowBuilder();

  const [size, ref] = useElementSize();
  const { getNode, fitView } = useReactFlow();
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(false);
  const [localSkillsData, setLocalSkillsData] = useState<Skill[]>([]);

  const [nodes, setNodes, onNodesChange] = useNodesState<CustomNode>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const { data: fetchedSkillsData, isLoading: isLoadingSkills } = useList<SkillData>({
    resource: "skills",
    filters: [
      {
        field: "app_id",
        operator: "eq",
        value: appId,
      },
      {
        field: "status_id",
        operator: "eq",
        value: 15,
      },
    ],
    sorters: [
      {
        field: "name",
        order: "asc",
      },
    ],
  });

  useEffect(() => {
    if (!isLoadingSkills && fetchedSkillsData) {
      const dynamicSkills: Skill[] = (fetchedSkillsData.data || [])
        .filter((skill: SkillData): skill is Skill => 
          typeof skill.keyword === 'string' && 
          skill.id !== undefined && 
          typeof skill.name === 'string'
        )
        .map((skill: SkillData): Skill => ({
          keyword: skill.keyword,
          id: skill.id,
          name: skill.name
        }));
      setLocalSkillsData(dynamicSkills);
    }
  }, [isLoadingSkills, fetchedSkillsData]);

  const createNodesAndEdges = useMemo(() => {
    if (localSkillsData.length === 0) return { nodes: [], edges: [] };
  
    const nodes: CustomNode[] = [
      {
        id: "start-node",
        type: "startNode",
        position: { x: 820, y: 0 },
        data: {
          label: "Request",
          stepType: "start",
          subLabel: "User Prompt....",
          days: 1,
          actionTitle: "start",
          condition: null,
          branch: null,
          sourceYOffset: -50,
          targetYOffset: -50,
          selectedExample: null,
          isCompleted: true,
          nodeLabel: "Request"
        },
        width: 360,
        height: 126,
        selected: false,
        targetPosition: "top" as Position,
        sourcePosition: "bottom" as Position,
        positionAbsolute: { x: 820, y: 0 }
      }
    ];
  
    const edges: Edge[] = [];
  
    localSkillsData.forEach((skill, index) => {
      const xOffset = index * 410;
  
      const apiSkillNode: CustomNode = {
        id: `api-skill-${skill.keyword}`,
        type: "StepNode",
        position: { x: xOffset, y: 200 },
        data: {
          label: "API / Skill",
          subLabel: "",
          stepType: "email",
          days: 1,
          actionTitle: "api_skills",
          condition: null,
          branch: null,
          skills: skill.id,
          nodeLabel: skill.name, // Use skill.name directly
          isCompleted: true
        },
        width: 300,
        height: 95,
        targetPosition: "top" as Position,
        sourcePosition: "bottom" as Position
      };
  
      const endNode: CustomNode = {
        id: `end-node-${skill.keyword}`,
        type: "FloatNode",
        position: { x: xOffset, y: 375 },
        data: {
          label: "End",
          subLabel: "AI Response",
          stepType: "end",
          days: 0,
          actionTitle: "end",
          condition: null,
          branch: null,
          isCompleted: true,
          nodeLabel: "AI Response"
        },
        width: 360,
        height: 160,
        targetPosition: "top" as Position,
        sourcePosition: "bottom" as Position,
        positionAbsolute: { x: xOffset, y: 375 }
      };
  
      const edgeFromStart: Edge = {
        id: `start-to-skill-${skill.keyword}`,
        source: "start-node",
        target: `api-skill-${skill.keyword}`,
        type: "bridge",
        data: {
          condition: "",
          icon: true,
          skillLabel: skill.name, // Use skill.name directly
          totalSkills: localSkillsData.length,
          skillIndex: index
        }
      };
  
      const edgeToEndNode: Edge = {
        id: `api-to-end-${skill.keyword}`,
        source: `api-skill-${skill.keyword}`,
        target: `end-node-${skill.keyword}`,
        type: "custom",
        data: {
          condition: "",
          icon: false
        }
      };
  
      nodes.push(apiSkillNode, endNode);
      edges.push(edgeFromStart, edgeToEndNode);
    });
  
    return { nodes, edges };
  }, [localSkillsData]);
  useEffect(() => {
    if (isInitialized) return;

    if (json_store && json_store !== "null" && json_store !== "{}") {
      try {
        const parsed = JSON.parse(json_store);
        if (parsed.nodes && parsed.edges) {
          setNodes(parsed.nodes);
          setEdges(parsed.edges);
          setIsInitialized(true);
          return;
        }
      } catch (error) {
        console.error("Error parsing json_store:", error);
      }
    }

    if (localSkillsData.length > 0) {
      const { nodes: newNodes, edges: newEdges } = createNodesAndEdges;
      setNodes(newNodes);
      setEdges(newEdges);
      setIsInitialized(true);
    }
  }, [json_store, localSkillsData, createNodesAndEdges, isInitialized, setNodes, setEdges]);

  const layoutedElements = useMemo(() => {
    if (nodes.length === 0 || edges.length === 0) return { layoutNodes: [], layoutEdges: [] };
    const elements = getLayoutedElements([...nodes, ...edges]);
    const layoutNodes = elements.filter((x: any) => x.position);
    return {
      layoutNodes,
      layoutEdges: elements.filter((x: any) => !x.position),
    };
  }, [nodes, edges]);

  useEffect(() => {
    if (layoutedElements.layoutNodes.length > 0) {
      const timer = setTimeout(() => {
        fitView({ 
          minZoom: 0.2, 
          duration: 500,
          padding: 0.05
        });
      }, 50);
  
      return () => clearTimeout(timer);
    }
  }, [layoutedElements, fitView, state.nodeSideBarOpen.open]);

  useEffect(() => {
    if (isSaved) {
      mutate({
        resource: "apps",
        values: {
          name: name,
          json_store: JSON.stringify({ nodes, edges }),
        },
        id,
      });
      setIsSaved(false);
    }
  }, [isSaved, mutate, name, id, nodes, edges]);

  const defaultEdgeStyle = {
    stroke: THEME_COLOR,
    strokeWidth: BORDER_WIDTH,
  };

  return (
    <div>
      <div
        style={{
          height: "calc(100vh - 200px)",
          width: "calc(100vw - 350px)",
          display: "flex",
          justifyContent: "center",
          gap: "0.5rem",
          backgroundColor: "#F8F9FA",
          position: "relative",
        }}
      >
        <div
          ref={ref}
          style={{
            width: sideBarOpen ? "calc(100vw - 750px)" : "calc(100vw - 350px)",
            backgroundColor: "#F8F9FA",
           
            transitionProperty: "width",
            transitionDuration: "300ms",
          }}
        >
          <ReactFlow
            nodes={layoutedElements.layoutNodes}
            edges={layoutedElements.layoutEdges}
            nodesDraggable={false}
            nodesConnectable={true}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            proOptions={proOptions}
            zoomOnScroll={false}
            zoomOnPinch={false}
            zoomOnDoubleClick={false}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            panOnScroll
            fitView={true}
            panOnDrag
            onPaneClick={() => {
              dispatch({
                type: "NODE_SIDE_BAR",
                payload: {
                  open: false,
                  activeId: "",
                },
              });
            }}
            preventScrolling
            onEdgeClick={(e, f) => {
              setCurrentEdge(f);
              setOnlySteps(true);
              setIsModalOpen(true);
            }}
            onNodeClick={(e: any, f) => handleNodeClick(e, f)}
            defaultEdgeOptions={{ style: defaultEdgeStyle }}
          >
            <Panel
              onClick={() => toJSON({ nodes, edges })}
              position="top-left"
            >
              Download
            </Panel>
            <Controls showInteractive={false} className="Controls" />
            <Background
        id="1"
        gap={10}
        color="#ffd6e5"
        size={2}
        variant={BackgroundVariant.Dots}
      />
 
      <Background
        id="2"
        color="#ffd6e5"
        size={6}
        variant={BackgroundVariant.Cross}
      />
          </ReactFlow>
        </div>
        {state.nodeSideBarOpen.open && currentSideData.id && (
          <SideBar
            sideBarOpen={state.nodeSideBarOpen.open}
            setSideBarOpen={setSideBarOpen}
            currentNode={currentSideData}
            setNodes={setNodes}
            setIsSaved={setIsSaved}
          />
        )}
      </div>
      <SelectNodeModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setSelectedIndex={setSelectedIndex}
      >
        <NodeSelectTab
          stepActionHandle={stepActionHandle}
          setIsSaved={setIsSaved}
          setSelectedIndex={setSelectedIndex}
          selectedIndex={selectedIndex}
          conditionActionHandle={conditionActionHandle}
          onlySteps={onlySteps}
        />
      </SelectNodeModal>
    </div>
  );
};

export default AppFlowBuilderComponent;
