import { supabaseClient } from "../../../utility/supabaseClient";
import { ContentItem } from "./types";

export const docBuilderService = {
  async fetchDocuments() {
    console.log('Fetching documents...');
    
    const { data, error } = await supabaseClient
      .from('docs')
      .select('*')
      .order('created_at', { ascending: false });
    
    if (error) {
      console.error('Error fetching documents:', error);
      throw error;
    }
    
    console.log('Raw data from database:', data);
    
    const processedData = data.map(doc => {
      console.log('Processing doc:', doc);
      console.log('Doc parent_id:', doc.parent_id);
      return {
        ...doc,
        // Ensure parent_id is explicitly null if not set
        parent_id: doc.parent_id || null,
        properties: doc.properties || {
          hasChildren: false,
          childProperties: []
        }
      };
    });

    console.log('Processed documents:', processedData);
    console.log('Documents with null parent_id:', processedData.filter(doc => doc.parent_id === null));
    
    return processedData as ContentItem[];
  },

  async updateDocument(document: ContentItem) {
    const { data, error } = await supabaseClient
      .from('docs')
      .update(document)
      .eq('id', document.id)
      .single();
    
    if (error) throw error;
    return data;
  },

  async createDocument(document: Omit<ContentItem, 'id'>) {
    const { data, error } = await supabaseClient
      .from('docs')
      .insert({
        ...document,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      })
      .single();
    
    if (error) throw error;
    return data;
  },

  async generatePDF(documentId: string) {
    // PDF generation logic here
    const { data, error } = await supabaseClient
      .rpc('generate_doc_pdf', { doc_id: documentId });
    
    if (error) throw error;
    return data;
  },

  async publishDocument(documentId: string) {
    const { data, error } = await supabaseClient
      .from('docs')
      .update({ 
        status: 'published', 
        published_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      })
      .eq('id', documentId)
      .single();
    
    if (error) throw error;
    return data;
  }
}; 