import { useEffect, useState } from 'react';
import { supabaseClient as supabase } from "../../utility";
import { Grid } from '../../components/grid'; // Adjust the path according to your file structure
import { UUID } from 'crypto';

interface AppData {
  id: UUID;
  title: string;
  description: string;
  category: string;
  acl: string;
  app_type: string;
  image_url: string;
  is_installed: boolean;
}

export const AppsSearch = () => {

  const [cardsData, setCardsData] = useState<AppData[] | null>(null);

  //console.log("AppCard props:", { title, description, category, app_type, acl, image_url, is_installed, id });

  useEffect(() => {
    async function fetchApps() {
      const { data, error } = await supabase.rpc('get_apps_with_install_status');
      if (error) {
        console.error('Error fetching apps:', error);
      } else {
        setCardsData(data);
      }
    }

    fetchApps();
  }, []);

  return (
    <div>
      {cardsData && <Grid cardsData={cardsData} />}
    </div>
  );
}

export default AppsSearch;
