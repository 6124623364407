import { createContext, useReducer } from "react";
import { reducer } from "../contexts/reducer";
export const GlobalContext = createContext({});

const data = {
  workspace_name: "",
  invited_members: [],
  nodeSideBarOpen: {
    open: false,
    activeId: "",
    changeNode: false,
  },
};

const ContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, data);
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};
export default ContextProvider;
