import React, { FC, useState } from "react";
import {
  EdgeProps,
  EdgeLabelRenderer,
  BaseEdge,
  getSmoothStepPath,
} from "reactflow";
import { PlusOutlined } from "@ant-design/icons";
import { THEME_COLOR, BORDER_WIDTH } from '../../../utility/constants';

function SkillSwitcherLabel({ transform, label }: { transform: string; label: string }) {
  return (
    <div
      style={{
        fontSize: 20,
        transform,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "700",
        borderRadius: "14px",
        padding: "0px 12px 3px 12px",
        minWidth: "3rem",
        height: "2.5rem",
        backgroundColor: 'white',
        boxShadow: "0 1px 20px rgba(0, 0, 0, 0.1)",
        border: `0px solid white`,
      }}
      className="nodrag nopan"
    >
      <span style={{ color: 'black' }}>{label}</span>
    </div>
  );
}

function ConditionLabel({ transform, label }: { transform: string; label: string }) {
  return (
    <div
      style={{
        fontSize: 12,
        transform,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "700",
        borderRadius: "14px",
        padding: "0px 6px 0px 6px",
        minWidth: "2rem",
        height: "1.3rem",
        backgroundColor: "white",
        boxShadow: "0 1px 20px rgba(0, 0, 0, 0.1)",
        border: `1px solid ${label === "Yes" ? "#96D9AA" : "#F1CCCC"}`,
      }}
      className="nodrag nopan"
    >
      <span
        style={{
          color: label === "Yes" ? "#22C55E" : "#C73131",
        }}
      >
        {label}
      </span>
    </div>
  );
}

const CustomEdge: FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const isSkillSwitcherEdge = id.startsWith('skill-switcher-');

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY: sourceY - 10,
    sourcePosition,
    targetX,
    targetY: targetY + 20,
    targetPosition,
    borderRadius: 0,
  });

  //console.log("data custom ", data);

  let labelPosition = { x: labelX, y: labelY };
  if (isSkillSwitcherEdge) {
    const totalSkills = data.totalSkills || 1;
    const skillIndex = data.skillIndex || 0;
    const spreadFactor = 410;

    labelPosition.y = (sourceY + targetY)/2 ;

    if (totalSkills === 1) {
      labelPosition.x = sourceX;
    } else {
      const customDistribution = (index: number, total: number) => {
        return index / (total - 1) * 2 - 1;
      };

      const offset = customDistribution(skillIndex, totalSkills);
      const maxOffset = ((totalSkills - 1) * spreadFactor) / 2;
      labelPosition.x = sourceX + offset * maxOffset;
    }
  }

  return (
    <>
      <BaseEdge 
        id={id} 
        path={edgePath} 
        style={{
          stroke: THEME_COLOR,
          strokeWidth: BORDER_WIDTH,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <EdgeLabelRenderer>
        {isSkillSwitcherEdge ? (
          <SkillSwitcherLabel
            transform={`translate(-50%, -50%) translate(${labelPosition.x}px,${labelPosition.y}px)`}
            label={data.skillLabel}
          />
        ) : data.condition && (
          <ConditionLabel
            transform={`translate(-50%, -50%) translate(${labelX}px,${labelY}px)`}
            label={data.condition}
          />
        )}
        {data.icon && (
          <div
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${targetX}px,${
                targetY - 20
              }px)`,
              fontSize: 12,
              zIndex: 10,
              pointerEvents: "all",
            }}
            className="nodrag nopan"
          >
            <button
              style={{
                width: "30px",
                height: "30px",
                background: isHovered ? THEME_COLOR : "white",
                cursor: "pointer",
                borderRadius: "50%",
                fontSize: "12px",
                padding: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "0px",
                color: isHovered ? "white" : THEME_COLOR,
                border: `${BORDER_WIDTH}px solid ${THEME_COLOR}`,
                transition: "all 0.3s ease",
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <PlusOutlined style={{ fontSize: '16px' }} />
            </button>
          </div>
        )}
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
