import React from 'react';

export const AppSchedulerCreate = () => {
  return (
    <div>
      <h1>App Scheduler Create</h1>
      <p>...</p>
    </div>
  );
}

