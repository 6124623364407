import React, { useState } from 'react';
import styles from "./index.module.css";
import { Card, Image, Text, Badge, Button, Group, Modal } from '@mantine/core';
import { supabaseClient as supabase } from "../../../utility";
import { motion } from "framer-motion";
import { UUID } from 'crypto';
import { AppPreferences } from '../../custom/AppPreferences'; // Assuming this component is created in a separate file

export const AppCard = ({ title, description, category, app_type, acl, image_url, is_installed, app_id }: { title: string; description: string; category: string; app_type: string; acl: string; image_url: string; is_installed: boolean, app_id: UUID }) => {
  const [isInstalled, setIsInstalled] = useState(is_installed);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInstallToggle = async () => {
    if (isInstalled) {
      try {
        const { data, error } = await supabase.rpc('uninstall_app', { app_id: app_id });
        if (error) throw error;
        setIsInstalled(false);
      } catch (error) {
        console.error('Error uninstalling app:', error);
      }
    } else {
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleInstallConfirm = async (preferences: any) => {
    try {
      const { data, error } = await supabase.rpc('install_app', { app_id: app_id, preferences: preferences });
      if (error) throw error;
      setIsInstalled(true);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error installing app:', error);
    }
  };

  return (
    <motion.div
      className={styles.wrapper2}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
    >
      <Card shadow="sm" radius="md" withBorder>
        <Card.Section>
          <Image
            src={image_url || "https://assets.digitalhealthpassport.co/file/ZenBot/Apps/Images/holdingImage.png"}
            height={160}
          />
        </Card.Section>

        <Group mt="md" mb="xs">
          <Text size="lg" fw={500}>{title}</Text>
        </Group>

        <Text size="sm" c="dimmed" style={{ height: '85px', overflow: 'hidden' }}>
          {description}
        </Text>

        <Group mt="md" mb="xs">
          <Badge color="pink">{category}</Badge> <Badge color="paleblue">{app_type}</Badge>
        </Group>

        <Button bg={isInstalled ? "blue" : "green"} fullWidth mt="md" radius="md" onClick={handleInstallToggle}>
          {isInstalled ? 'Uninstall' : 'Install'}
        </Button>
      </Card>

      <Modal opened={isModalOpen} onClose={handleModalClose} title="Configure App Installation"   
      size="40%" // This sets the modal to 80% of the viewport width
  styles={(theme) => ({
    modal: {
      minWidth: '500px',
      // Ensure it doesn't exceed the viewport on smaller screens
      maxWidth: '100%',
    },
  })}>
        <AppPreferences app_id={app_id} onConfirm={handleInstallConfirm} onCancel={handleModalClose} />
      </Modal>
    </motion.div>
  );
};