import { getConnectedEdges, getIncomers, getOutgoers, useReactFlow, Edge, Node } from 'reactflow';
import { EdgeTypes, NodeTypes, addNewFloatNode, checkduplicity, removeSimilarById } from '../../components/thirdparty/flowbuilder/Utils';

type NodeData = {
    label: string;
    subLabel: string;
    stepType: string;
    days: number;
    actionTitle: string;
    condition: any;
    branch: any;
    nodeLabel?: string;
};

function useNodeDelete() {
    const { setNodes, setEdges, getEdges, getNodes } = useReactFlow();
    const nodesOrigin = getNodes();
    const edges = getEdges();

    const handleDelete = (deleteId: string) => {
        const currentNode = nodesOrigin.find(item => item.id === deleteId);
        if (!currentNode) return;
        
        if (currentNode.type === NodeTypes.Condition) {
            handleConditionDelete(currentNode);
        } else {
            handleRegularNodeDelete(currentNode);
        }
    }

    const handleConditionDelete = (currentNode: Node) => {
        const azimData = removeTreeOfOutgoers(currentNode);
        const checkDuplic = checkduplicity(azimData.flat())
        const floatNode = addNewFloatNode();
        
        setNodes((nodes: Node[]) => {
            const nodesCopy = nodes.map(node => ({ ...node }));
            const combinedArray = removeSimilarById(nodesCopy, checkDuplic) as unknown as Node[];
            return [...combinedArray, floatNode] as Node[];
        });

        setEdges((edges) => {
            const incomingEdges = edges.filter((x) => x.target === currentNode.id);
            const outgoingEdges = edges.filter((x) => x.source === currentNode.id);
            const updatedIncomingEdges = incomingEdges.map((x) => ({
                ...x,
                target: floatNode.id,
                data: { ...x.data, condition: x.data?.condition, icon: x.data?.icon },
                type: EdgeTypes.default
            }));
            return edges
                .filter(x => !incomingEdges.includes(x) && !outgoingEdges.includes(x))
                .concat(updatedIncomingEdges);
        });
    }

    const handleRegularNodeDelete = (currentNode: any) => {
        console.log("Deleting node:", currentNode);
        const connectedEdges: any = getConnectedEdges([currentNode], edges);
        console.log("Connected edges:", connectedEdges);
        const incomers = getIncomers(currentNode, nodesOrigin, edges);
        console.log("Incoming nodes:", incomers);
        const outgoers = getOutgoers(currentNode, nodesOrigin, edges);
        console.log("Outgoing nodes:", outgoers);
    
        setNodes((nodes) => nodes.filter(item => item.id !== currentNode.id));
    
        setEdges((edges) => {
            let updatedEdges = [...edges];
    
            // Find the index of the edge we're going to replace
            const edgeIndex = updatedEdges.findIndex(edge => 
                edge.source === currentNode.id || edge.target === currentNode.id
            );
    
            // Remove edges connected to the deleted node
            updatedEdges = updatedEdges.filter(edge => 
                edge.source !== currentNode.id && edge.target !== currentNode.id
            );
            console.log("Edges after removing connections to deleted node:", updatedEdges);
    
            if (incomers.length > 0) {
                const incomingEdge = connectedEdges.find((e: Edge) => e.target === currentNode.id);
                console.log("Incoming edge:", incomingEdge);
                
                // Check if this node is connected to an end node
                const endNodeEdge = connectedEdges.find((e: Edge) => 
                    e.source === currentNode.id && 
                    (e.id.includes('-to-end-') || nodesOrigin.find(node => node.id === e.target)?.type === 'FloatNode')
                );
                console.log("End node edge found:", endNodeEdge);
    
                if (endNodeEdge) {
                    console.log("Node is connected to an end node");
                    // Remove the "add new" button edge above the deleted node
                    updatedEdges = updatedEdges.filter(edge => {
                        const shouldKeep = !(edge.target === currentNode.id && edge.type === 'bridge');
                        if (!shouldKeep) {
                            console.log("Removing 'add new' button edge:", edge);
                        }
                        return shouldKeep;
                    });
    
                    // Connect the incoming node directly to the end node
                    const newEdge = {
                        ...incomingEdge,
                        id: `e${incomers[0].id}-${endNodeEdge.target}`,
                        source: incomers[0].id,
                        target: endNodeEdge.target,
                        type: 'custom',
                        data: { ...incomingEdge.data, icon: false }
                    };
                    console.log("New edge created to connect to end node:", newEdge);
                    if (edgeIndex !== -1) {
                        updatedEdges.splice(edgeIndex, 0, newEdge);
                    } else {
                        updatedEdges.push(newEdge);
                    }
                } else if (outgoers.length > 0) {
                    console.log("Node is in the middle of the flow");
                    const outgoingEdge = connectedEdges.find((e: Edge) => e.source === currentNode.id);
                    console.log("Outgoing edge:", outgoingEdge);
                    const newEdge = {
                        ...incomingEdge,
                        id: incomingEdge.source === 'start-node' 
                            ? `start-to-${outgoers[0].id}`
                            : `e${incomers[0].id}-${outgoers[0].id}`,
                        source: incomers[0].id,
                        target: outgoers[0].id,
                        type: 'bridge',
                        data: { 
                            ...incomingEdge.data, 
                            icon: true,
                            skillLabel: outgoers[0].data.nodeLabel,
                            totalSkills: incomingEdge.data.totalSkills,
                            skillIndex: incomingEdge.data.skillIndex
                        },
                        style: incomingEdge.style
                    };
                    console.log("New edge created:", newEdge);
                    if (edgeIndex !== -1) {
                        updatedEdges.splice(edgeIndex, 0, newEdge);
                    } else {
                        updatedEdges.push(newEdge);
                    }
                } else {
                    console.log("Node is not connected to an end node and has no outgoers");
                }
            } else {
                console.log("Node has no incoming connections");
            }
    
            console.log("Final updated edges:", updatedEdges);
            return updatedEdges;
        });
    }

    let storedData: any = [];
    function removeTreeOfOutgoers(newNode: any) {
        const outgoers = getOutgoers(newNode, nodesOrigin, edges);
        storedData.push([...outgoers, newNode]);
        if (outgoers.length) {
            outgoers.forEach((outgoer) => removeTreeOfOutgoers(outgoer));
        }
        return storedData;
    }

    return { handleDelete };
}

export default useNodeDelete;