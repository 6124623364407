import { useState } from 'react';
import classes from './_gridLayout.module.css';
import { Title } from '@mantine/core';
import { useTitles } from "../../../contexts/TitleProviderContext"; 

export function A3() {
  const { pageHeaderTitle } = useTitles();
  
  const capitalizedTitle = pageHeaderTitle.charAt(0).toUpperCase() + pageHeaderTitle.slice(1);

  return (
    <div className={classes.gridA3}>
      <Title order={4}>{capitalizedTitle}</Title>
    </div>
  );
}

export default A3; 