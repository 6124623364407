import { Button, ButtonProps } from '@mantine/core';

function AppleIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="16" height="16" overflow="visible" viewBox="0 0 857.52942 999.99992">
  <g fillRule="evenodd" clipRule="evenodd" transform="translate(3e-8 -.00025013) scale(.85753)">
    <path fill="#61bb46" stroke="#61bb46" strokeMiterlimit="2.61310005" strokeWidth="6.28089046" d="M54.232461 423.0098c.348572-.57566.718141-1.14287 1.087711-1.69954C115.05402 328.95469 209.27966 274.90015 297.88826 274.90015c90.22756 0 146.94605 49.66861 221.52778 49.66861 72.36642 0 116.44394-49.74031 220.75925-49.74031 78.81501 0 162.37771 43.09815 221.89737 117.62109-16.45637 9.05021-31.30215 19.32551-44.57099 30.6193l-863.269209-.0589z"/>
    <path fill="#61bb46" stroke="#61bb46" strokeMiterlimit="2.61310005" strokeWidth="6.28089046" d="M661.04954 191.61588c37.86412-48.83359 66.66537-117.78556 56.22711-188.28314-61.90716 4.26153-134.31558 43.84038-176.60824 95.36246-38.37648 46.8051-70.05869 116.22095-57.7243 183.65049 67.5872 2.11073 137.50311-38.41488 178.10543-90.72981z"/>
    <path fill="#fdb827" d="M51.151054 421.64891C25.42683 462.11947 7.6276563 520.12542 2.9042342 571.00351l834.5498858-.0215c9.02332-55.66242 39.02667-108.84145 86.58225-149.27157l-872.885316-.0615h.04925z"/>
    <path fill="#f5821f" stroke="#f5821f" strokeMiterlimit="2.61310005" strokeWidth="6.28089046" d="M10.482518 719.0309c-8.1095407-51.96489-9.4219338-102.06365-4.3277482-148.02531l828.0487802-.0213c-8.09905 49.84363-1.30189 101.71362 19.18405 148.07802l-842.905082-.0317z"/>
    <path fill="#e03a3e" stroke="#e03a3e" strokeMiterlimit="2.61310005" strokeWidth="6.28089046" d="M50.950428 867.04567c-19.55361-49.72976-32.942121-99.67671-40.46791-148.01477l842.905082.0317c26.09457 59.07939 74.38644 109.27093 142.38522 135.06361-2.02003 4.51035-3.96868 8.82458-5.84383 12.98278l-938.978562-.0632z"/>
    <path fill="#963d97" stroke="#963d97" strokeMiterlimit="2.61310005" strokeWidth="6.28089046" d="M989.9269 867.10682c-23.34591 51.54106-36.8793 78.29106-69.18727 127.67077-4.34665 6.63161-8.83819 13.41511-13.51452 20.26181l-778.04344.072c-2.4925-3.7281-4.9619-7.454-7.3935-11.1504-29.088937-44.49017-52.638522-90.61629-70.837742-136.91744l938.976472.0632z"/>
    <path fill="#009ddc" stroke="#009ddc" strokeMiterlimit="2.61310005" strokeWidth="6.28089046" d="M907.22511 1015.0394c-47.59473 70.0126-111.49253 147.0574-189.02664 147.7891-75.65684.6895-95.06557-49.4409-197.70942-48.8758-102.64386.5757-124.07261 49.7509-199.72945 49.0403-80.88753-.7507-143.69762-76.4165-191.57793-147.8819l778.04344-.072z"/>
  </g>
</svg>
  );
}

export function AppleButton(props: ButtonProps & React.ComponentPropsWithoutRef<'button'>) {
  return (
    <Button
      leftIcon={<AppleIcon />}
      variant="default"
      color="dark"
      {...props}
    >
      Sign in with Apple
    </Button>
  );
}