import React from 'react';

function SupportVideos() {
  return (
    <div>
      <h1>Support Videos</h1>
      <p>Videos here.</p>
    </div>
  );
}

export default SupportVideos;