export const reducer = (
  state: {
    workspace_name?: any;
    invited_members?: any;
  },
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case "WORKSPACE_NAME":
      return { ...state, workspace_name: action.payload };
    case "INVITED_MEMBERS":
      return { ...state, invited_members: action.payload };
    case "NODE_SIDE_BAR":
      return { ...state, nodeSideBarOpen: action.payload };
    default:
      return state;
  }
};
