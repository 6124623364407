import React from 'react';
import { Text } from '@mantine/core';

function SupportHelp() {
  return (
    <div>
      <h1>Support - Help</h1>
      <p>Manage tickets here.</p>
      <Text style={{ fontSize: '100px' }}>🤖</Text>
    </div>
  );
}

export default SupportHelp;