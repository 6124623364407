import { Avatar } from '@mantine/core';
import { useGetIdentity } from "@refinedev/core";
import { IIdentity } from "../../../interfaces";
import classes from './_gridLayout.module.css';

interface C1Props {
  onAvatarClick: () => void;
}

export function C1({ onAvatarClick }: C1Props) {
  const { data: identity } = useGetIdentity<IIdentity>();
  const name = identity?.user_metadata?.full_name || identity?.email.split('@')[0];
  const firstLetter = name ? name[0].toUpperCase() : '';
  const avatarUrl = identity?.user_metadata?.avatar_url;

  return (
    <div className={classes.gridC1}>
      <Avatar
        src={avatarUrl || undefined}
        radius="xl"
        size="md"
        color="blue"
        onClick={onAvatarClick}
        style={{ cursor: 'pointer' }}
      >
        {!avatarUrl && firstLetter}
      </Avatar>
    </div>
  );
} 