import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import { Button, Group, Stack } from '@mantine/core';
import { IconBold, IconItalic, IconH2, IconH3, IconList, IconListNumbers, IconQuote, IconLink } from '@tabler/icons-react';

interface TipTapEditorProps {
  content: string;
  onUpdate: (content: string) => void;
}

export default function TipTapEditor({ content, onUpdate }: TipTapEditorProps) {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link,
    ],
    content,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      console.log('Content updated:', html);
    }
  });

  const handleSave = () => {
    if (editor) {
      onUpdate(editor.getHTML());
    }
  };

  if (!editor) {
    return null;
  }

  return (
    <Stack spacing="xs">
      <Group spacing="xs">
        <Button.Group>
          <Button
            variant={editor.isActive('bold') ? 'filled' : 'light'}
            onClick={() => editor.chain().focus().toggleBold().run()}
            size="xs"
          >
            <IconBold size={16} />
          </Button>
          <Button
            variant={editor.isActive('italic') ? 'filled' : 'light'}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            size="xs"
          >
            <IconItalic size={16} />
          </Button>
        </Button.Group>

        <Button.Group>
          <Button
            variant={editor.isActive('heading', { level: 2 }) ? 'filled' : 'light'}
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            size="xs"
          >
            <IconH2 size={16} />
          </Button>
          <Button
            variant={editor.isActive('heading', { level: 3 }) ? 'filled' : 'light'}
            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            size="xs"
          >
            <IconH3 size={16} />
          </Button>
        </Button.Group>

        <Button.Group>
          <Button
            variant={editor.isActive('bulletList') ? 'filled' : 'light'}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            size="xs"
          >
            <IconList size={16} />
          </Button>
          <Button
            variant={editor.isActive('orderedList') ? 'filled' : 'light'}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            size="xs"
          >
            <IconListNumbers size={16} />
          </Button>
          <Button
            variant={editor.isActive('blockquote') ? 'filled' : 'light'}
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            size="xs"
          >
            <IconQuote size={16} />
          </Button>
        </Button.Group>

        <Button
          variant={editor.isActive('link') ? 'filled' : 'light'}
          onClick={() => {
            const url = window.prompt('URL');
            if (url) {
              editor.chain().focus().setLink({ href: url }).run();
            }
          }}
          size="xs"
        >
          <IconLink size={16} />
        </Button>
      </Group>

      <EditorContent editor={editor} />
      
      <Group position="right" mt="md">
        <Button onClick={handleSave}>Save</Button>
      </Group>
    </Stack>
  );
} 