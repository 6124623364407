import { useEffect } from "react";
import { useTitles } from "../../../contexts/TitleProviderContext"; 
import { Edit, useForm, useSelect } from "@refinedev/mantine";
import { Select, TextInput, Textarea, Checkbox, Grid, Stack, Text, Group, Popover } from "@mantine/core";
import { IconHelp } from '@tabler/icons-react';
import { IOption } from '../../../interfaces';
import { useParams } from 'react-router-dom';

// Update the IApp interface
interface IApp {
    name: string;
    status_id: number;
    category_id: string;
    description: string;
    image_url: string;
    acl_id?: string;
    app_type_id?: string;
    uses_cookies: boolean;
    user_supplied_url: boolean;
    [key: string]: string | number | boolean | undefined;
}

const HelpPopover = ({ label, helpText }: { label: string; helpText: string }) => (
  <Group spacing="xs" noWrap>
    <Text weight={500} size="sm">{label}</Text>
    <Popover width={200} position="top" withArrow shadow="md">
      <Popover.Target>
        <IconHelp size={16} style={{ cursor: 'pointer' }} />
      </Popover.Target>
      <Popover.Dropdown>
        <Text size="sm">{helpText}</Text>
      </Popover.Dropdown>
    </Popover>
  </Group>
);

export const AppEdit: React.FC = () => {
    const { id } = useParams();

    // Define default initial values
    const defaultInitialValues: IApp = {
        name: "",
        status_id: 5,
        category_id: "",
        description: "",
        image_url: "",
        acl_id: "",
        app_type_id: "",
        uses_cookies: false,
        user_supplied_url: false,
    };

    const {
        saveButtonProps,
        getInputProps,
        refineCore: { queryResult },
    } = useForm<IApp>({
        refineCoreProps: {
            action: "edit",
            resource: "apps",
            id: id,
            queryOptions: {
                enabled: !!id,
            },
        },
        initialValues: defaultInitialValues as Record<string, unknown>,
        validate: {
            name: (value: string) => (value.length < 2 ? "Too short title" : null),
            status_id: (value: number) => (value <= 0 ? "Status is required" : null),
            category_id: (value: number) => (value <= 0 ? "Category is required" : null),
        },
    });

    const appData = queryResult?.data?.data;

    const { setDocumentTitle, setPageHeaderTitle  } = useTitles();

    useEffect(() => {
        if (appData) {
            const headerTitle =  appData.name + " 🚀";
            setDocumentTitle("zen > apps > " + headerTitle);
            setPageHeaderTitle(headerTitle);
        } 
    }, [setDocumentTitle, setPageHeaderTitle, appData]);

    useEffect(() => {
        if (appData) {
            console.log('Form Data:', appData);
        }
    }, [appData]);

    // Update form values when data is available
    useEffect(() => {
        if (appData) {
            Object.keys(defaultInitialValues).forEach((key) => {
                const value = appData[key as keyof IApp] ?? defaultInitialValues[key as keyof IApp];
                getInputProps(key).onChange(value);
            });
        }
    }, [appData]);

    const { selectProps: appCatOpts } = useSelect<IOption>({
        resource: "options",
        filters: [
            {
                field: "parent_id",
                operator: "eq",
                value: 1,
            },
        ],
        optionLabel: "name",  
        optionValue: "id", 
        defaultValue: appData?.category_id ?? "",
    });

    const { selectProps: statusOpts } = useSelect<IOption>({
        resource: "options",
        filters: [
            {
                field: "parent_id",
                operator: "eq",
                value: 2,
            },
        ],
        optionLabel: "name",  
        optionValue: "id", 
        defaultValue: appData?.status_id ?? 5,
    });

    const { selectProps: aclOpts } = useSelect<IOption>({
        resource: "options",
        filters: [
            {
                field: "parent_id",
                operator: "eq",
                value: 14,
            },
        ],
        optionLabel: "name",  
        optionValue: "id", 
        defaultValue: appData?.acl_id ?? "",
    });

    const { selectProps: appTypeOpts } = useSelect<IOption>({
        resource: "options",
        filters: [
            {
                field: "parent_id",
                operator: "eq",
                value: 30,
            },
        ],
        optionLabel: "name",  
        optionValue: "id", 
        defaultValue: appData?.app_type_id ?? "",
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <form>
                <TextInput
                    mt={8}
                    label={<HelpPopover label="Name" helpText="The name of the app. This should be unique and easily identifiable." />}
                    placeholder="Name"
                    {...getInputProps("name")}
                />
                <Textarea
                    mt={8}
                    label={<HelpPopover label="Description" helpText="A detailed description of the app, its purpose, and key features." />}
                    placeholder="Description"
                    autosize
                    minRows={8}
                    {...getInputProps("description")}   
                />
                <Grid mt={8}>
                    <Grid.Col span={4}>
                        <Select
                            label={<HelpPopover label="Status" helpText="The current status of the app. The app will become available to you once published. Additionaly you can change the ACL to make available to your team or everyone. " />}
                            placeholder="Pick one"
                            {...getInputProps("status_id")}
                            {...statusOpts}
                        />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Select
                            label={<HelpPopover label="Category" helpText="The category is used in the filter of available apps under search." />}
                            placeholder="Pick one"
                            {...getInputProps("category_id")}
                            {...appCatOpts}
                        />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Select
                            label={<HelpPopover label="ACL" helpText="Access Control List: Who can access and use this application. Defaults to private." />}
                            placeholder="Pick one"
                            {...getInputProps("acl_id")}
                            {...aclOpts}
                        />
                    </Grid.Col>
                </Grid>
                <Grid mt={8}>
                    <Grid.Col span={4}>
                        <Select
                            label={<HelpPopover label="App Type" helpText="The type or source of the app (e.g. AppSumo, RapidAPI, Public or Private API)." />}
                            placeholder="Pick one"
                            {...getInputProps("app_type_id")}
                            {...appTypeOpts}
                        />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <TextInput
                            label={<HelpPopover label="Image URL" helpText="The URL of an image representing the app, used in the filter of available apps under search." />}
                            placeholder="URL"
                            {...getInputProps("image_url")}
                        />
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <Stack spacing="xs">
                            <HelpPopover label="Uses Cookies" helpText="App uses cookies to store authentication required for subsequent API calls." />
                            <Checkbox
                                {...getInputProps("uses_cookies", { type: "checkbox" })}
                            />
                        </Stack>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <Stack spacing="xs">
                            <HelpPopover label="User Supplied URL" helpText="App hosted by end user. E.g. Seafile API (Dropbox alternative)." />
                            <Checkbox
                                {...getInputProps("user_supplied_url", { type: "checkbox" })}
                            />
                        </Stack>
                    </Grid.Col>
                </Grid>
            </form>
        </Edit>
    );
};
