import React from 'react';
import { Typography, Form, Input, Select, Checkbox, Row, Col, Button, Modal, Collapse } from 'antd';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';

interface ParamsComponentProps {
  paramsColumn: string;
}

const ParamsComponent: React.FC<ParamsComponentProps> = ({ paramsColumn }) => {
  return (
    <Form.List name={paramsColumn}>
      {(fields, { add, remove }, { errors }) => (
        <>
          <Collapse accordion bordered={true}>
            {fields.map(({ key, name, fieldKey }, index) => (
              <Collapse.Panel
                header={
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues !== currentValues}
                  >
                    {({ getFieldValue }) => {
                      const paramName = getFieldValue([paramsColumn, index, 'name']) || 'Unnamed';
                      const paramValue = getFieldValue([paramsColumn, index, 'value']) || 'No Value';
                      return (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography.Text strong>
                            {`${paramName}: ${paramValue}`}
                          </Typography.Text>
                          <CloseOutlined
                            style={{ cursor: 'pointer' }}
                            onClick={(event) => {
                              event.stopPropagation();
                              Modal.confirm({
                                title: 'Are you sure you want to delete this parameter?',
                                onOk: () => remove(name),
                              });
                            }}
                          />
                        </div>
                      );
                    }}
                  </Form.Item>
                }
                key={key}
                forceRender
              >
                <div>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'name']}
                        label="Name"
                        rules={[{ required: true, message: 'Name is required' }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.paramsColumn?.[name]?.type !== currentValues.paramsColumn?.[name]?.type ||
                          prevValues.paramsColumn?.[name]?.multiSelect !== currentValues.paramsColumn?.[name]?.multiSelect
                        }
                      >
                        {({ getFieldValue }) => {
                          const type = getFieldValue([paramsColumn, name, 'type']);
                          const multiSelect = getFieldValue([paramsColumn, name, 'multiSelect']);
                          const enumValues = getFieldValue([paramsColumn, name, 'enumValues']) || '';
                          const options = enumValues.split(',').map((value: string) => ({ value: value.trim(), label: value.trim() }));
                          if (type === 'enum') {
                            return (
                              <Form.Item
                                name={[name, 'value']}
                                label="Default Value"
                                rules={[{ required: true, message: 'Value is required' }]}
                              >
                                <Select
                                  mode={multiSelect ? 'multiple' : undefined}
                                  placeholder="Select Value"
                                  options={options}
                                />
                              </Form.Item>
                            );
                          } else {
                            return (
                              <Form.Item
                                name={[name, 'value']}
                                label="Default Value"
                                rules={[{ required: true, message: 'Value is required' }]}
                              >
                                <Input />
                              </Form.Item>
                            );
                          }
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'type']}
                        label="Type"
                        rules={[{ required: true, message: 'Type is required' }]}
                      >
                        <Select>
                          <Select.Option value="enum">Enum</Select.Option>
                          <Select.Option value="string">String</Select.Option>
                          <Select.Option value="number">Number</Select.Option>
                          <Select.Option value="boolean">Boolean</Select.Option>
                          <Select.Option value="date">Date</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.paramsColumn?.[name]?.type !== currentValues.paramsColumn?.[name]?.type
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue([paramsColumn, name, 'type']) === 'enum' ? (
                            <Form.Item
                              name={[name, 'enumValues']}
                              label="Enum Values"
                              rules={[{ required: true, message: 'Enum Values are required' }]}
                            >
                              <Input placeholder="Enter Enum Values" />
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name={[name, 'required']}
                        label="Required"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name={[name, 'urlPath']}
                        label="UrlPath"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name={[name, 'multiSelect']}
                        label="multiSelect"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item
                        name={[name, 'description']}
                        label="Description"
                      >
                        <Input.TextArea />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Collapse.Panel>
            ))}
          </Collapse>
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusCircleOutlined />}
              style={{ marginTop: '20px' }}
            >
              Add Parameter
            </Button>
          </Form.Item>
          {/* Handle and display errors if necessary */}
        </>
      )}
    </Form.List>
  );
};

export default ParamsComponent;
