// passwordReset.tsx

import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import { PasswordInput, Button, Paper, Title, Text, Alert } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconAlertCircle } from '@tabler/icons-react';
import authProvider from '../../authProvider';

export function PasswordResetForm() {
  const [resetStatus, setResetStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [resetMessage, setResetMessage] = useState('');
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: {
      password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
      confirmPassword: (val, values) => (val !== values.password ? 'Passwords do not match' : null),
    },
  });

  const handleSubmit = async (values: typeof form.values) => {
    if (values.password !== values.confirmPassword) {
      form.setFieldError('confirmPassword', 'Passwords do not match');
      return;
    }

    setResetStatus('loading');
    setResetMessage('Updating password...');

    try {
      if (authProvider.updatePassword) {
        const result = await authProvider.updatePassword({ password: values.password });
        if (result.success) {
          setResetStatus('success');
          setResetMessage('Password updated successfully. Redirecting to login...');
          setTimeout(() => navigate('/login'), 3000);
        } else {
          throw new Error(result.error?.message || 'Unknown error');
        }
      } else {
        throw new Error('Update password functionality not available');
      }
    } catch (error) {
      console.error('Password update error:', error);
      setResetStatus('error');
      setResetMessage('Failed to update password. Please try again.');
    }
  };

  return (
    <Paper radius="md" p="xl" withBorder>
      <Title order={2} mb="md">Reset Your Password</Title>
      <Text color="dimmed" mb="md">Enter your new password below</Text>
      {resetStatus !== 'idle' && (
        <Alert icon={<IconAlertCircle size="1rem" />} title={resetStatus.toUpperCase()} color={resetStatus === 'error' ? 'red' : 'blue'} mt="md" mb="md">
          {resetMessage}
        </Alert>
      )}
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <PasswordInput
          required
          label="New Password"
          placeholder="Enter your new password"
          {...form.getInputProps('password')}
          mb="md"
        />
        <PasswordInput
          required
          label="Confirm New Password"
          placeholder="Confirm your new password"
          {...form.getInputProps('confirmPassword')}
          mb="md"
        />
        <Button type="submit" fullWidth mt="xl" loading={resetStatus === 'loading'}>
          Reset Password
        </Button>
      </form>
    </Paper>
  );
}