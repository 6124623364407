import React from 'react';

export const AppsActive = () => {
  return (
    <div>
      <h1>Apps Active</h1>
      <p>Apps Active here.</p>
    </div>
  );
}
