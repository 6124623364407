import React from 'react';
import { Paper, Text, TextInput, Select, MultiSelect, NumberInput, SimpleGrid, Box } from '@mantine/core';

interface SkillDataItem {
  name: string;
  type: string;
  value: string | number | string[];
  enumValues?: string;
  multiSelect?: boolean;
  required?: boolean;
}

interface SkillPreferencesProps {
  skillData: {
    id: string;
    name: string;
    description: string;
    params: SkillDataItem[];
    body_data: SkillDataItem[];
    url: string;
  };
  preferences: Record<string, any>;
  onChange: (field: string, value: any) => void;
}

export const SkillPreferences: React.FC<SkillPreferencesProps> = ({ skillData, preferences, onChange }) => {
  const formatLabel = (label: string): string => {
    return label
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const renderFormElement = (item: SkillDataItem, type: 'param' | 'body') => {
    const id = `${type}_${item.name}`;
    const value = preferences[id] || item.value;

    const commonProps = {
      id,
      label: formatLabel(item.name),
      required: item.required,
      description: item.required ? 'Required' : 'Optional'
    };

    switch (item.type) {
      case 'number':
        return (
          <NumberInput
            {...commonProps}
            value={value as number}
            onChange={(val) => onChange(id, val)}
          />
        );
      case 'enum':
        if (item.multiSelect) {
          return (
            <MultiSelect
              {...commonProps}
              data={item.enumValues?.split(',').map(formatLabel) || []}
              value={value as string[]}
              onChange={(val) => onChange(id, val)}
            />
          );
        } else {
          return (
            <Select
              {...commonProps}
              data={item.enumValues?.split(',').map(formatLabel) || []}
              value={value as string}
              onChange={(val) => onChange(id, val)}
            />
          );
        }
      default:
        return (
          <TextInput
            {...commonProps}
            value={value as string}
            onChange={(event) => onChange(id, event.currentTarget.value)}
          />
        );
    }
  };

  const allInputs = [...skillData.params, ...skillData.body_data];

  return (
    <Paper p="md" withBorder style={{ backgroundColor: '#FFF0F5' }}> {/* Pale pink background */}
  
      <Box p="sm" mb="md" style={{ backgroundColor: '#E6F3FF' }}> {/* Pale blue background for description */}
        <Text size="sm">{skillData.description}</Text>
      </Box>
      
      <SimpleGrid
        cols={4}
        spacing="md"
        breakpoints={[
          { maxWidth: 'md', cols: 3, spacing: 'sm' },
          { maxWidth: 'sm', cols: 2, spacing: 'sm' },
          { maxWidth: 'xs', cols: 1, spacing: 'sm' },
        ]}
      >
        {allInputs.map((item, index) => (
          <div key={index}>
            {renderFormElement(item, index < skillData.params.length ? 'param' : 'body')}
          </div>
        ))}
      </SimpleGrid>

      <TextInput
        label="URL"
        value={skillData.url}
        readOnly
        mt="md"
      />
    </Paper>
  );
};