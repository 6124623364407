import React, { useState } from "react";
import { useUpdate } from "@refinedev/core";
import { Button, Popover, Text, Group } from "@mantine/core";
import { IconTrash, IconPencil } from '@tabler/icons-react';

export const SoftDeleteButtonX = ({ resource, recordItemId }: { resource: string, recordItemId: string }) => {
    const { mutate } = useUpdate();
    const [opened, setOpened] = useState(false);

    const handleSoftDelete = () => {
        console.log("Mutation parameters:", { resource, id: recordItemId, values: { status_id: -1 } });
        
        mutate({
            resource,
            id: recordItemId,
            values: { status_id: -1 },
        }, {
            onSuccess: (data) => {
                console.log("Mutation successful:", data);
                setOpened(false);
                //setNotification({ type: 'success', message: 'Item deleted successfully' });
            },
            onError: (err) => {
                console.error("Mutation error:", err);
                //setNotification({ type: 'error', message: 'Failed to delete item' });
            }
        });
    };

    return (
        <Popover
            opened={opened}
            onChange={setOpened}
            position="bottom"
            withArrow
        >
            <Popover.Target>
                <Button 
                    onClick={() => setOpened((o) => !o)} 
                    variant="outline" 
                    color="red"
                >
                    <IconTrash color="red" size={16} />
                </Button>
            </Popover.Target>
            <Popover.Dropdown>
                <Text>Are you sure you want to delete this item?</Text>
                <Group position="center" mt="md">
                    <Button variant="outline" color="gray" onClick={() => setOpened(false)}>
                        Cancel
                    </Button>
                    <Button variant="filled" color="red" onClick={handleSoftDelete}>
                        Confirm
                    </Button>
                </Group>
            </Popover.Dropdown>
        </Popover>
    );
};


export const EditButtonX = ({ prepath, recordItemId, postpath }: { prepath: string, recordItemId: string, postpath: string }) => {
    return (
        <Button component="a" href={`${prepath}/${recordItemId}/${postpath}`} variant="outline" color="blue" ><IconPencil color="blue" size={16} /></Button>
    );
};


//http://localhost:5173/apps/builder/edit/9346e949-de1e-408f-b6a0-0c90c1f7d30f/core