import { Handle, Position } from "reactflow";
import Flowlayout from "./Flowlayout";
import { useState } from "react";
import TimerHelper from "./NodeHelper/TimerHelper";
import useTimerNode from "../../../hooks/reactflow/useTimerNode";

const TimerNode = (props: any) => {
  const [open, setOpen] = useState(false);
  const { incrementCounter, decrementCounter, handler, counter } =
    useTimerNode();

  return (
    <div className="relative">
      <Handle
        type="target"
        style={{ visibility: "hidden" }}
        position={Position.Top}
      />
      <Flowlayout
        nodeData={props}
        type="TimerNode"
        onClick={() => setOpen(!open)}
      />
      {open && (
        <TimerHelper
          counter={counter}
          decrementCounter={decrementCounter}
          handler={handler}
          incrementCounter={incrementCounter}
          suspectedId={props.id}
        />
      )}
      <Handle
        type="source"
        style={{ visibility: "hidden" }}
        position={Position.Bottom}
      />
    </div>
  );
};
export default TimerNode;
