import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useReactFlow, Node } from "reactflow";
import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";

import { 
  THEME_COLOR, 
  BORDER_WIDTH, 
  NODE_BOX_SHADOW, 
  SELECTED_NODE_BG_COLOR,
  SELECTED_NODE_BORDER_COLOR,
  SELECTED_NODE_BOX_SHADOW,
  SELECTED_NODE_BORDER_WIDTH
} from '../../../utility/constants';

type NodeData = {
  label?: string;
  stepType?: string;
  days?: number;
  actionTitle?: string;
  skills?: string;
  node?: string;
  nodeLabel?: string;
  requiredField?: string;
  paramValue?: string;
  isCompleted?: boolean;
};

type LayoutProps = {
  nodeData: Node<NodeData>;
  type: string;
  onClick?: () => void;
};

const Flowlayout: React.FC<LayoutProps> = React.memo((props) => {
  const { nodeData, type } = props;
  const [isHovered, setIsHovered] = useState(false);
  const { getNode } = useReactFlow();

  const node = getNode(nodeData.id);
  const isSelected = node?.selected || false;

  const { data } = nodeData;

  const onMouseEnter = useCallback(() => setIsHovered(true), []);
  const onMouseLeave = useCallback(() => setIsHovered(false), []);

  const isCompleted = useMemo(() => data.isCompleted, [data.isCompleted]);

  const CompletionStatus: React.FC = () => (
    <div style={{ position: "absolute", top: "10px", right: "10px" }}>
      {isCompleted ? (
        <CheckCircleFilled style={{ color: "#52c41a", fontSize: "1.2rem" }} />
      ) : (
        <ExclamationCircleFilled style={{ color: "#faad14", fontSize: "1.2rem" }} />
      )}
    </div>
  );

  const getNodeType = (actionTitle: string | undefined): string => {
    switch (actionTitle) {
      case "api_skills":
        return "Skill";
      case "llm_api":
        return "LLM";
      case "await_task_completed":
        return "Await Task Completed";
      case "required_field_missing":
        return "Required Field";
      case "param_value_match_criteria":
        return "Criteria Matched";
      default:
        return "Unknown Type";
    }
  };

  const LabelRenderer: React.FC<{ type: string; data: NodeData }> = React.memo(({ type, data }) => {
    if (type === "TimerNode" && data.days !== undefined) {
      return (
        <p className="font-semibold text-gray-900">
          {`Wait for`}{" "}
          <span className="text-[#316bff]">{`${data.days} ${
            data.days === 1 ? "day" : "days"
          }`}</span>
        </p>
      );
    }
    
    let mainLabel = "Not Configured";
    let subLabel = "";

    if (isCompleted) {
      mainLabel = data.nodeLabel || "Configured";
      subLabel = getNodeType(data.actionTitle);
    } else {
      mainLabel = getNodeType(data.actionTitle);
      subLabel = "Not Configured";
    }
    
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
        <p style={{
          fontWeight: "700",
          color: "#1a202c",
          fontSize: "1.5rem",
          marginBottom: "0.5rem",
        }}>
          {mainLabel}
        </p>
        <p style={{
          fontWeight: "500",
          color: "#4a5568",
          fontSize: "0.875rem",
        }}>
          {subLabel}
        </p>
      </div>
    );
  });

  useEffect(() => {
    if (isSelected) {
      const style = document.createElement('style');
      style.innerHTML = `
        @keyframes ripple {
          0% {
            box-shadow: 0 0 0 0 rgba(255, 0, 128, 0.2), 0 0 0 5px rgba(255, 0, 128, 0.2), 0 0 0 10px rgba(255, 0, 128, 0.2);
          }
          100% {
            box-shadow: 0 0 0 5px rgba(255, 0, 128, 0.2), 0 0 0 10px rgba(255, 0, 128, 0.2), 0 0 0 15px rgba(255, 0, 128, 0);
          }
        }
      `;
      document.head.appendChild(style);
      return () => {
        document.head.removeChild(style);
      };
    }
  }, [isSelected]);

  const nodeStyle = useMemo(() => {
    return {
      display: "flex",
      alignItems: "center",
      boxShadow: isSelected || isHovered ? SELECTED_NODE_BOX_SHADOW : NODE_BOX_SHADOW,
      height: "90px",
      width: "300px",
      backgroundColor: isHovered ? SELECTED_NODE_BG_COLOR : "#fff",
      color: "#000",
      borderRadius: "10px",
      border: `${isSelected ? SELECTED_NODE_BORDER_WIDTH : BORDER_WIDTH}px solid ${isSelected || isHovered ? SELECTED_NODE_BORDER_COLOR : THEME_COLOR}`,
      transition: "all 0.3s ease",
      padding: "20px",
      marginTop: "0px",
      overflow: "hidden",
      animation: isSelected ? "ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite" : "none",
      position: "relative" as "relative",
    };
  }, [isSelected, isHovered]);

  return (
    <div
      style={nodeStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: "0"}}>
        <LabelRenderer type={type} data={data} />
      </div>
      {data.actionTitle !== "choose_skill" && <CompletionStatus />}
    </div>
  );
});

export default Flowlayout;