import { v4 as uuidv4 } from "uuid";
import { flowIcons } from "./flowIcons";
import _ from "lodash";

export const NodeTypes = {
  StepNode: "StepNode",
  TimerNode: "TimerNode",
  Condition: "Condition",
  startNode: "startNode",
  FloatNode: "FloatNode",
};

export const EdgeTypes = {
  bridge: "bridge", //only icon
  custom: "custom", //with label and icon
  default: "default",
};

export const ActionTitles = {
  api_skills: "api_skills",
  llm_api: "llm_api",
  await_task_completed: "await_task_completed",
  required_field_missing: "required_field_missing",
  param_value_match_criteria: "param_value_match_criteria",
};

export const nodeTypeStage = [
  {
    id: 1,
    label: "API / Skill",
    Icon: flowIcons.Email,
    type: "StepNode",
    stepType: "email",
    actionTitle: "api_skills",
  },
  {
    id: 2,
    label: "LLM API",
    Icon: flowIcons.LinkedIn,
    type: "StepNode",
    stepType: "email",
    actionTitle: "llm_api",
  },
  {
    id: 3,
    label: "Await Task Completed",
    Icon: flowIcons.Email,
    type: "StepNode",
    stepType: "email",
    actionTitle: "await_task_completed",
  },
];

export const isSideBarOpen = Object.values(ActionTitles).slice(2, 8);
export const noErrorToNode = Object.values(ActionTitles).slice(0, 2);

export const nodeTypeCondtion = [
  {
    id: 1,
    label: " Is required field missing?",
    Icon: flowIcons.Email,
    type: "Condition",
    stepType: "email",
    actionTitle: "required_field_missing",
  },
  {
    id: 2,
    label: "Does param value match criteria?",
    Icon: flowIcons.LinkedIn,
    type: "Condition",
    stepType: "linkedin",
    actionTitle: "param_value_match_criteria",
  },
];

export const addNewNode = (data: any) => {
  const newFlowId = uuidv4();
  const newNode = {
    id: newFlowId,
    type: data.type,
    position: { x: 100, y: 200 },
    data: {
      label: data.label,
      subLabel: "",
      stepType: data.stepType,
      days: 1,
      actionTitle: data.actionTitle,
      condition: null,
      branch: null,
    },
  };
  return newNode;
};

export const addNewFloatNode = () => {
  const newFlowId = uuidv4();
  const newNode = {
    id: newFlowId,
    type: NodeTypes.FloatNode,
    position: { x: 0, y: 0 },
    data: {
      label: "",
      subLabel: "",
      stepType: "",
      days: 1,
      actionTitle: "",
      condition: null,
      branch: null,
    },
  };
  return newNode;
};

export const addTimerNode = () => {
  const newFlowId = uuidv4();
  const newNode = {
    id: newFlowId,
    type: NodeTypes.TimerNode,
    position: { x: 100, y: 200 },
    data: {
      label: "",
      subLabel: "",
      stepType: "timer",
      days: 1,
      actionTitle: "",
      condition: null,
      branch: null,
    },
  };
  return newNode;
};

export const addNewConditionEdge = (
  sourceId: string,
  targetId: string,
  condition: string,
  icon: boolean,
  additionalData: any = {}
) => {
  const newEdgeId = uuidv4();
  const newEdge = {
    id: newEdgeId,
    source: sourceId,
    target: targetId,
    type: EdgeTypes.custom,
    data: {
      condition,
      icon,
      ...additionalData,
    },
  };
  return newEdge;
};

export const addNewEdge = (sourceId: string, targetId: string, type: string, data: any = {}) => {
  return {
    id: uuidv4(),
    source: sourceId,
    target: targetId,
    type: type,
    data: {
      icon: false,
      condition: "",
      ...data,
    },
  };
};

export const addNewSkillSwitcherEdge = (
  sourceId: string,
  targetId: string,
  skillLabel: string,
  skillIndex: number,
  totalSkills: number
) => {
  return addNewEdge(sourceId, targetId, "custom", {
    skillLabel,
    skillIndex,
    totalSkills,
    icon: false,
    condition: "",
  });
};
export const checkduplicity = (arrayData: any) => {
  const itemsData = arrayData.filter((value: any, index: number) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      arrayData.findIndex((obj: any) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
  return itemsData;
};

export function toJSON(elements: any) {
  const downloadLink = document.createElement("a");
  const fileBlob = new Blob([JSON.stringify(elements, null, 2)], {
    type: "application/json",
  });
  downloadLink.href = URL.createObjectURL(fileBlob);
  downloadLink.download = `prospect_.json`;
  downloadLink.click();
}

export const removeDuplicates = (array1: any, array2: any) =>
  array1.filter((item: any) => array2.includes(item));

export function removeDuplicatesById(array1: any, array2: any) {
  const combinedArray = [...array1, ...array2];
  const uniqueArray = _.uniqBy(combinedArray, "id");
  return uniqueArray;
}

export function removeSimilarById(array1: any, array2: any) {
  const uniqueArray1 = _.differenceBy(array1, array2, "id");
  return uniqueArray1;
}

