export const THEME_COLOR = '#1890ff';
export const THEME_COLOR_DARK = '#0f1582';
export const BORDER_WIDTH = 3;

// New constants for node styling
export const NODE_WIDTH = 360;
export const NODE_HEIGHT = 80;
export const CIRCLE_SIZE = 120;
export const MAIN_FONT_SIZE = 18;
export const SUB_FONT_SIZE = 10;
export const SUB_FONT_COLOR = '#F4F4F4';
export const NODE_BACKGROUND_COLOR = 'white';
export const NODE_BOX_SHADOW = '0 5px 50px rgba(0, 0, 0, 0.1)';
export const TEXT_GRADIENT_CONFIG = {
    from: 'indigo',
    to: 'cyan',
    deg: 4
  };
  export const SELECTED_NODE_BORDER_WIDTH = 3;
  export const SELECTED_NODE_BG_COLOR = "#ffa0c4"; //Pale pink highlight
  export const SELECTED_NODE_BORDER_COLOR = "#ff0080"; //Hot Pink highlight
  export const SELECTED_NODE_BOX_SHADOW = "0 0 0 2px rgba(255, 0, 128, 0.5), 0 4px 10px rgba(0, 0, 0, 0.2)";