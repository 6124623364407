import { useEffect, useState } from 'react';
import { useTitles } from "../../contexts/TitleProviderContext"; 
import { Edit, useForm, useSelect } from '@refinedev/antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Form, Input, Button, Row, Col, Select, Collapse } from 'antd';
import { useParams } from 'react-router-dom';
import { useNavigation } from "@refinedev/core";

import APITest from '../../components/custom/AppBuilder/tabSkills/apiTest';
import Params from '../../components/custom/AppBuilder/tabSkills/params';

interface Header {
  name: string;
  value: string;
}

interface IOption {
  id: number;
  name: string;
}

export const AppBuilderSkillEdit = () => {
  const { id, id2 } = useParams();

  const [headerCount, setHeaderCount] = useState(0); 
  const [bodyDataCount, setBodyDataCount] = useState(0); 
  const [paramCount, setParamCount] = useState(0); 

  const { push } = useNavigation();

  const {
    saveButtonProps,
    formProps,
    queryResult,
    form
  } = useForm({
    action: "edit",
    resource: "skills",
    id: id2,
    redirect: false,
    onMutationSuccess: () => {
      push(`/apps/builder/edit/${id}/skills`);
    },
  });

  const { selectProps: statusOpts } = useSelect<IOption>({
    resource: "options",
    filters: [
      {
        field: "parent_id",
        operator: "eq",
        value: 2,
      },
    ],
    optionLabel: "name",
    optionValue: "id",
  });

  const { selectProps: bodyTypeOpts } = useSelect<IOption>({
    resource: "options",
    filters: [
      {
        field: "parent_id",
        operator: "eq",
        value: 17,
      },
    ],
    optionLabel: "name",
    optionValue: "id",
  });

  const [url, setUrl] = useState('');
  const [method, setMethod] = useState('');
  const [headers, setHeaders] = useState<Header[]>([]);
  const [params, setParams] = useState([]);
  const [bodyData, setBodyData] = useState([]);
  const [curl_json, setCurlJson] = useState('');
  const [urlPath, setUrlPath] = useState('');
  const [bodyTypeDisabled, setIsBodyTypeDisabled] = useState(false);

  const data = queryResult?.data?.data;

  const { setDocumentTitle, setPageHeaderTitle } = useTitles();

  useEffect(() => {
    if (data) {
      const headerTitle = data.name + " 🚀";
      setDocumentTitle("apps skill " + headerTitle);
      setPageHeaderTitle(headerTitle);

      if (!data.params) {
        data.params = [];
      }
      if (!data.body_data) {
        data.body_data = [];
      }

      form.setFieldsValue({
        ...data,
      });

      setHeaderCount(data.headers?.length || 0);
      setParamCount(data.params?.length || 0);
      setBodyDataCount(data.body_data?.length || 0);
      setParams(data.params);
      setHeaders(data.headers);
      setBodyData(data.body_data);
      setUrl(data.url);
      setMethod(data.method);
      setCurlJson(data.curl_json);

      const urlPath = data.params
        .filter((p: { urlPath: string; }) => p.urlPath)
        .map((p: { name: string; }) => `{{${p.name}}}`)
        .join('/');
      setUrlPath(`/${urlPath}/`);
    }
  }, [form, queryResult, data, setDocumentTitle, setPageHeaderTitle]);

  useEffect(() => {
    if (method === 'GET') {
      setIsBodyTypeDisabled(true);
    } else {
      setIsBodyTypeDisabled(false);
    }
  }, [method, form]);

  const handleMethodChange = (value: string) => {
    if (value === 'GET') {
      form.setFieldsValue({ body_type_id: 18 });
      setIsBodyTypeDisabled(true);
    } else {
      form.setFieldsValue({ body_type_id: 21 });
      setIsBodyTypeDisabled(false);
    }
  };

  const updatePanelHeader = (header: Header) => {
    return <span style={{ fontWeight: 'bold' }}>{`${header.name || 'Header'}: ${header.value || ''}`}</span>;
  };

  return (
    <Edit saveButtonProps={saveButtonProps} headerButtons={() => null}
      headerProps={{
        title: null,
        subTitle: null,
        style: {
          marginTop: "-50px",
        },
      }}>
      <Form {...formProps} layout="vertical">
        <Collapse accordion defaultActiveKey={['1']}>
          <Collapse.Panel header="Core" key="1">
            <Row gutter={16}>
              <Col span={20}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: 'Name is required' }]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="status_id"
                  label="Status"
                  rules={[{ required: true, message: 'Status is required' }]}
                >
                  <Select {...statusOpts} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[{ required: true, message: 'Description is required' }]}
                >
                  <Input.TextArea rows={4} placeholder="Enter a description for this skill" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={10}>
                <Form.Item
                  name="url"
                  label="URL (endpoint)"
                  rules={[{ required: true, message: 'URL is required' }]}
                >
                  <Input placeholder="Endpoint URL" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  id="urlPath"
                  label="URL Path"
                >
                  <Input placeholder="URL Path" disabled value={urlPath} />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="method"
                  label="Action Type"
                  rules={[{ required: true, message: 'Method is required' }]}
                >
                  <Select placeholder="Select a method" onChange={handleMethodChange}>
                    <Select.Option value="GET">GET</Select.Option>
                    <Select.Option value="POST">POST</Select.Option>
                    <Select.Option value="PUT">PUT</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="body_type_id"
                  label="Body Type"
                >
                  <Select {...bodyTypeOpts} disabled={bodyTypeDisabled} />
                </Form.Item>
              </Col>
            </Row>
            <div style={{ background: '#f0f0f0', padding: '10px', borderRadius: '4px', marginTop: '20px' }}>
              <p style={{ margin: 0 }}>
                Important: To add dynamic segments to the url path add a param below and tick the segment checkbox.
              </p>
            </div>
          </Collapse.Panel>

          <Collapse.Panel header={`Headers (${headerCount})`} key="2">
            <Form.List name="headers">
              {(fields, { add, remove }) => {
                return (
                  <>
                    <Collapse accordion bordered={true}>
                      {fields.map(({ key, name }, index) => (
                        <Collapse.Panel
                          header={updatePanelHeader(headers[index] || {})}
                          key={key}
                          forceRender
                        >
                          <Row gutter={24}>
                            <Col span={12}>
                              <Form.Item
                                name={[name, 'name']}
                                label="Name"
                                rules={[{ required: true, message: 'Name is required' }]}
                              >
                                <Input onChange={(e) => {
                                  const updatedHeaders = [...headers];
                                  updatedHeaders[index].name = e.target.value;
                                  setHeaders(updatedHeaders);
                                }} />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name={[name, 'value']}
                                label="Value"
                                rules={[{ required: true, message: 'Value is required' }]}
                              >
                                <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  const updatedHeaders = [...headers];
                                  updatedHeaders[index].value = e.target.value;
                                  setHeaders(updatedHeaders);
                                }} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Collapse.Panel>
                      ))}
                    </Collapse>
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusCircleOutlined />}
                        style={{ marginTop: '20px' }}
                      >
                        Add Header
                      </Button>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          </Collapse.Panel>
          <Collapse.Panel header={`Parameters (${paramCount})`} key="3">
            <Params paramsColumn='params' />
          </Collapse.Panel>
          <Collapse.Panel header={`Data (${bodyDataCount})`} key="4">
            <Params paramsColumn='body_data' />
          </Collapse.Panel>

          <Collapse.Panel header="API Test" key="5">
            <APITest
              curl_json={curl_json}
              url={url}
              method={method}
              headers={headers}
              params={params}
              bodyData={bodyData}
              app_id={id || ''}
            />
          </Collapse.Panel>
          <Collapse.Panel header="Bot Test" key="6">
            <p>{/* Content for Bot Tests goes here */}</p>
          </Collapse.Panel>
        </Collapse>
      </Form>
    </Edit>
  );
};