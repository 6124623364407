// loginRegister.tsx

import React, { useState, useCallback, useEffect } from 'react';
import { useToggle, upperFirst } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { 
  TextInput, 
  PasswordInput, 
  Text, 
  Paper, 
  Group, 
  PaperProps, 
  Button, 
  Divider, 
  Checkbox, 
  Anchor, 
  Stack, 
  Alert 
} from '@mantine/core';
import { useLogin, useNavigation } from "@refinedev/core";
import { useNavigate } from 'react-router-dom';
import { IconLock, IconMail, IconAlertCircle } from '@tabler/icons-react';
import { GoogleButton } from './googleAuth';
import { AppleButton } from './appleAuth';
import authProvider from '../../authProvider';

type LoginVariables = {
  email?: string;
  password?: string;
  providerName?: string;
};

export function AuthenticationForm(props: PaperProps) {
  const [type, toggle] = useToggle(['login', 'register']);
  const [isResetMode, setIsResetMode] = useState(false);
  const [resetStatus, setResetStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [resetMessage, setResetMessage] = useState('');
  const { mutate: login } = useLogin<LoginVariables>();
  const navigate = useNavigate();
  const { push } = useNavigation();

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      terms: true,
      name: '',
    },
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
      password: (val) => (!isResetMode && val.length <= 6 ? 'Password should include at least 6 characters' : null),
    },
  });

  useEffect(() => {
    console.log('AuthenticationForm mounted');
    return () => console.log('AuthenticationForm unmounted');
  }, []);

  const onSubmit = useCallback(async (values: typeof form.values) => {
    if (isResetMode) {
      await handlePasswordReset();
    } else {
      console.log('Login/Register attempt with:', values);
      const redirectPath = type === 'login' ? '/apps-search' : '/onboarding';
      login(
        {
          email: values.email,
          password: values.password,
        },
        {
          onSuccess: (data) => {
            console.log('Login/Register successful:', data);
            push(redirectPath);
          },
          onError: (error) => {
            console.error('Login/Register failed:', error);
            // Handle login error (e.g., show error message)
          },
        }
      );
    }
  }, [type, login, push, isResetMode]);

  const handlePasswordReset = async () => {
    const email = form.values.email;
    if (!email) {
      form.setFieldError('email', 'Please enter your email to reset password');
      return;
    }
    setResetStatus('loading');
    setResetMessage('Sending password reset link...');
    
    try {
      console.log('Attempting to send password reset email to:', email);
      if (authProvider.forgotPassword) {
        const result = await authProvider.forgotPassword({ email });
        console.log('Password reset result:', result);
        
        if (result.success) {
          setResetStatus('success');
          setResetMessage('Password reset link sent to your email. Please check your inbox and click the link to reset your password.');
        } else {
          throw new Error(result.error?.message || 'Unknown error');
        }
      } else {
        throw new Error('Forgot password functionality not available');
      }
    } catch (error) {
      console.error('Password reset error:', error);
      setResetStatus('error');
      setResetMessage('Failed to send password reset link. Please try again later.');
    }
  };

  const toggleResetMode = () => {
    setIsResetMode(!isResetMode);
    setResetStatus('idle');
    setResetMessage('');
    form.reset();
  };

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    width: '100%',
    padding: '16px',
    zIndex: 1000,
    position: 'relative',
    overflow: 'hidden',
    margin: 0,
    boxSizing: 'border-box',
  };

  const actionText = isResetMode ? 'Reset your password' : (type === 'login' ? 'Login to your account' : 'Register for your account');

  return (
    <div style={containerStyle}>
      <div style={{ 
        width: '100%', 
        maxWidth: '500px',
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        margin: '0 auto',
        padding: '16px',
        boxSizing: 'border-box',
        overflow: 'auto',
        maxHeight: '100vh'
      }}>
        <div style={{ width: '100%', marginBottom: '5px' }}>
          <Text fw={700} ta="center" size="xl">Zen🤖Bot</Text>
        </div>

        <Paper radius="md" p="xl" withBorder {...props} style={{ width: '100%' }}>
          <Text size="lg" fw={500} style={{ textAlign: 'center' }}>
            {actionText}
          </Text>
          {resetStatus !== 'idle' && (
            <Alert icon={<IconAlertCircle size="1rem" />} title={resetStatus.toUpperCase()} color={resetStatus === 'error' ? 'red' : 'blue'} mt="md">
              {resetMessage}
            </Alert>
          )}
          {!isResetMode && (
            <>
              <Stack spacing="xs" mb="md" mt="md" w="100%">
                <GoogleButton radius="xl" onClick={() => login({ providerName: 'google' })}>
                  Google
                </GoogleButton>
                <AppleButton radius="xl" onClick={() => login({ providerName: 'apple' })}>
                  Apple
                </AppleButton>
              </Stack>

              <Divider label="Or continue with email" labelPosition="center" my="lg" />
            </>
          )}

          <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack>
              {type === 'register' && !isResetMode && (
                <TextInput
                  label="Name"
                  placeholder="Your name"
                  value={form.values.name}
                  onChange={(event) => form.setFieldValue('name', event.currentTarget.value)}
                  radius="md"
                />
              )}

              <TextInput
                required
                variant='filled'
                label="Email"
                placeholder="hello@zen.bot"
                icon={<IconMail size={16} />}
                value={form.values.email}
                onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                error={form.errors.email && 'Invalid email'}
                radius="md"
              />

              {!isResetMode && (
                <PasswordInput
                  required
                  variant='filled'
                  label="Password"
                  placeholder="Your password"
                  icon={<IconLock size={16} />}
                  value={form.values.password}
                  onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                  error={form.errors.password && 'Password should include at least 6 characters'}
                  radius="md"
                />
              )}

              {type === 'register' && !isResetMode && (
                <Checkbox
                  label="I accept terms and conditions"
                  checked={form.values.terms}
                  onChange={(event) => form.setFieldValue('terms', event.currentTarget.checked)}
                />
              )}
            </Stack>

            <Stack mt="md" spacing="xs">
              {!isResetMode && (
                <Anchor 
                  component="button" 
                  type="button" 
                  c="dimmed" 
                  onClick={() => toggle()} 
                  size="xs"
                  style={{ textAlign: 'center' }}
                >
                  {type === 'register'
                    ? 'Already have an account? Login'
                    : "Don't have an account? Register"}
                </Anchor>
              )}
              <Anchor 
                component="button" 
                type="button" 
                c={isResetMode ? "red" : "dimmed"} 
                onClick={toggleResetMode} 
                size="xs"
                style={{ textAlign: 'center' }}
              >
                {isResetMode ? 'Cancel password reset' : 'Forgot password?'}
              </Anchor>
            </Stack>

            <Button fullWidth mt="xl" type="submit" radius="xl">
              {isResetMode ? 'Reset Password' : upperFirst(type)}
            </Button>
          </form>
        </Paper>
      </div>
    </div>
  );
}