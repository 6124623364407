import classes from './_gridLayout.module.css';
import { Title } from '@mantine/core';

export function A2({ active }: { active: string }) {
  return (
    <div className={classes.gridA2}>
      <div className={classes.title}>
        <Title order={4}>{active}</Title>
      </div>
    </div>
  );
}

export default A2; 