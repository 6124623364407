import React, { useState } from 'react';
import { Card, Text, Button, PasswordInput, Group, Stack, Alert } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAlertCircle } from '@tabler/icons-react';
import authProvider from '../../authProvider';

function AccountProfile() {
  const [updateStatus, setUpdateStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [updateMessage, setUpdateMessage] = useState('');

  const passwordForm = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: {
      password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
      confirmPassword: (val, values) => (val !== values.password ? 'Passwords do not match' : null),
    },
  });

  const handlePasswordUpdate = async (values: typeof passwordForm.values) => {
    if (values.password !== values.confirmPassword) {
      passwordForm.setFieldError('confirmPassword', 'Passwords do not match');
      return;
    }

    setUpdateStatus('loading');
    setUpdateMessage('Updating password...');

    try {
      if (authProvider.updatePassword) {
        const result = await authProvider.updatePassword({ password: values.password });
        if (result.success) {
          setUpdateStatus('success');
          setUpdateMessage('Password updated successfully.');
          passwordForm.reset();
        } else {
          throw new Error(result.error?.message || 'Unknown error');
        }
      } else {
        throw new Error('Update password method not available');
      }
    } catch (error) {
      console.error('Password update error:', error);
      setUpdateStatus('error');
      setUpdateMessage('Failed to update password. Please try again.');
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Account Profile</h1>
      <Text mb="xl">Manage your Account Profile here.</Text>

      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
        <Card shadow="sm" p="lg" radius="md" withBorder style={{ width: '300px' }}>
          <Card.Section withBorder inheritPadding py="xs">
            <Text weight={500}>Update Password</Text>
          </Card.Section>

          <form onSubmit={passwordForm.onSubmit(handlePasswordUpdate)}>
            <Stack mt="md">
              <PasswordInput
                required
                label="New Password"
                placeholder="Enter your new password"
                {...passwordForm.getInputProps('password')}
              />
              <PasswordInput
                required
                label="Confirm New Password"
                placeholder="Confirm your new password"
                {...passwordForm.getInputProps('confirmPassword')}
              />
              <Button type="submit" loading={updateStatus === 'loading'}>
                Update Password
              </Button>
            </Stack>
          </form>

          {updateStatus !== 'idle' && (
            <Alert icon={<IconAlertCircle size="1rem" />} title={updateStatus.toUpperCase()} color={updateStatus === 'error' ? 'red' : 'blue'} mt="md">
              {updateMessage}
            </Alert>
          )}
        </Card>

        <Card shadow="sm" p="lg" radius="md" withBorder style={{ width: '300px' }}>
          <Card.Section withBorder inheritPadding py="xs">
            <Text weight={500}>Update Name</Text>
          </Card.Section>
          {/* Add form for updating name */}
        </Card>

        <Card shadow="sm" p="lg" radius="md" withBorder style={{ width: '300px' }}>
          <Card.Section withBorder inheritPadding py="xs">
            <Text weight={500}>Update Email</Text>
          </Card.Section>
          {/* Add form for updating email */}
        </Card>
      </div>
    </div>
  );
}

export default AccountProfile;
