import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Button, Typography } from 'antd';
import ReactJson from 'react-json-view';
import { supabaseClient } from "../../../../utility";

interface HeaderOrParam {
  name: string;
  value: string;
  urlPath?: boolean;
}

interface APITestProps {
  curl_json: string;
  url: string;
  headers: HeaderOrParam[];
  params: HeaderOrParam[];
  bodyData: HeaderOrParam[];
  method: string;
  app_id: string;
}

const APITest: React.FC<APITestProps> = ({ url, headers, params, bodyData, method, app_id }) => {
  const [form] = Form.useForm();
  const [request, setRequest] = useState({});
  const [response, setResponse] = useState<any>(null);
  const [usesCookies, setUsesCookies] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUsesCookies = async () => {
      console.log("Fetching uses_cookies for app_id:", app_id);
      setIsLoading(true);
      try {
        const { data, error } = await supabaseClient
          .from('apps')
          .select('uses_cookies')
          .eq('id', app_id)
          .single();

        if (error) throw error;

        console.log("Fetched uses_cookies:", data.uses_cookies);
        setUsesCookies(data.uses_cookies);
      } catch (error) {
        console.error("Error fetching uses_cookies:", error);
        setUsesCookies(false); // Default to false if there's an error
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsesCookies();
  }, [app_id]);

  const buildRequestJson = () => {
    const values = form.getFieldsValue(true);
    const headersObj: { [key: string]: string } = {};
    const urlParams: string[] = [];
    const queryParams: { [key: string]: string } = {};
    const bodyDataObj: { [key: string]: string } = {};

    // Process headers
    values.headers.forEach((header: HeaderOrParam) => {
      if (header.name && header.value) {
        headersObj[header.name] = header.value;
      }
    });

    // Process parameters
    values.params.forEach((param: HeaderOrParam) => {
      if (param.urlPath && param.value) {
        urlParams.push(param.value);
      } else if (param.value) {
        queryParams[param.name] = param.value;
      }
    });

    // Process body data
    values.bodyData.forEach((bodyItem: HeaderOrParam) => {
      if (bodyItem.name && bodyItem.value) {
        bodyDataObj[bodyItem.name] = bodyItem.value;
      }
    });

    // Construct URL with path parameters
    const baseUri = url;
    const urlWithPath = urlParams.length > 0
      ? `${baseUri.replace(/\/+$/, '')}/${urlParams.join('/')}`
      : baseUri;

    form.setFieldsValue({
      url: baseUri
    });

    const requestJson = {
      url: urlWithPath,
      header: headersObj,
      method: values.method || method,
      params: queryParams,
      body: bodyDataObj,
    };

    setRequest(requestJson);
  };

  useEffect(() => {
    form.setFieldsValue({
      url, method, headers, params, bodyData
    });
    buildRequestJson();
  }, [url, method, headers, params, bodyData, form]);

  const onFormChange = () => {
    buildRequestJson();
  };

  const handleTestAPI = async () => {
    console.log("handleTestAPI called, usesCookies:", usesCookies);
    try {
      const { data: { session } } = await supabaseClient.auth.getSession();
      if (!session) {
        throw new Error("User is not authenticated");
      }

      let requestBody: any = {
        curl_json: request
      };

      if (usesCookies) {
        requestBody.clientId = `${session.user.id}_${app_id}`;
      }

      console.log("Sending request body:", requestBody);

      const response = await fetch('https://dvyrjvdxxmkphcuuzrev.supabase.co/functions/v1/proxy-remote', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      const result = await response.json();
      console.log("Received response:", result);
      setResponse(result);
    } catch (error) {
      console.error("Error calling Edge Function:", error);
      setResponse({ error: "Failed to call API. See console for details." });
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Form form={form} onValuesChange={onFormChange}
            initialValues={{
              url: url,
              method: method
            }}>

            <Row gutter={24} style={{ marginBottom: '20px' }}>
              <Col span={12}>
                <Form.Item name="url" label="Test URL">
                  <Input placeholder="Test URL" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="method" label="Method">
                  <Input placeholder="Method" disabled />
                </Form.Item>
              </Col>
            </Row>

            <Form.List name="headers" initialValue={headers}>
              {(fields) => (
                <>
                  {fields.map(({ key, name }) => (
                    <Row gutter={24} key={key} style={{ marginBottom: '20px' }}>
                      <Col span={12}>
                        <Form.Item
                          name={[name, 'name']}
                          label="Header Name"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[name, 'value']}
                          label="Header Value"
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>

            <Form.List name="params" initialValue={params}>
              {(fields) => (
                <>
                  {fields.map(({ key, name }) => {
                    const isUrlPath = params[name]?.urlPath;

                    return (
                      <Row gutter={24} key={key} style={{ marginBottom: '20px' }}>
                        <Col span={12}>
                          <Form.Item
                            name={[name, 'name']}
                            label={`Param Name${isUrlPath ? ' (urlPath)' : ''}`}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={[name, 'value']}
                            label={`Param Value${isUrlPath ? ' (urlPath)' : ''}`}
                          >
                            <Input data-urlpath={isUrlPath ? 'true' : undefined} />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              )}
            </Form.List>

            <Form.List name="bodyData" initialValue={bodyData}> 
              {(fields) => (
                <>
                  {fields.map(({ key, name }) => (
                    <Row gutter={24} key={key} style={{ marginBottom: '20px' }}>
                      <Col span={12}>
                        <Form.Item
                          name={[name, 'name']}
                          label="Body Data Name"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[name, 'value']}
                          label="Body Data Value"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>

            <Button 
              type="primary" 
              onClick={handleTestAPI} 
              disabled={isLoading}
            >
              Test API {isLoading ? '(Loading...)' : usesCookies ? '(Uses Cookies)' : '(No Cookies)'}
            </Button>

          </Form>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Typography.Title level={4}>Request</Typography.Title>
          {request && typeof request === 'object' ? (
            <ReactJson src={request} />
          ) : (
            <Typography.Paragraph>{JSON.stringify(request)}</Typography.Paragraph>
          )}
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Typography.Title level={4}>Response</Typography.Title>
          <div style={{ width: 'calc(100vw - 400px)', height: '700px', overflow: 'auto' }}>
            {response && typeof response === 'object' ? (
              <ReactJson src={response} />
            ) : (
              <Typography.Paragraph>
                {JSON.stringify(response)}
              </Typography.Paragraph>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default APITest;
