import React, { useState } from 'react';
import { Button, Group, Accordion, Text, Avatar } from '@mantine/core';
import { useList } from '@refinedev/core';
import { SkillPreferences } from './SkillPreferences'; // Import the new component

interface Skill {
  id: string;
  name: string;
  description: string;
  image?: string;
  params: SkillDataItem[];
  body_data: SkillDataItem[];
  url: string;
}

interface SkillDataItem {
  name: string;
  type: string;
  value: string | number | string[];
  enumValues?: string;
  multiSelect?: boolean;
}

interface AppPreferencesProps {
  app_id: string;
  onConfirm: (preferences: any) => void;
  onCancel: () => void;
}

//const DEFAULT_IMAGE = "https://img.icons8.com/clouds/256/000000/futurama-bender.png";
const DEFAULT_IMAGE = "";

export const AppPreferences: React.FC<AppPreferencesProps> = ({ app_id, onConfirm, onCancel }) => {
  const [preferences, setPreferences] = useState<Record<string, any>>({});

  const { data, isLoading, isError } = useList<Skill>({
    resource: "skills",
    filters: [
      { field: "app_id", operator: "eq", value: app_id }
    ]
  });

  const handlePreferenceChange = (skillId: string, field: string, value: any) => {
    setPreferences(prev => ({
      ...prev,
      [skillId]: { ...prev[skillId], [field]: value }
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onConfirm(preferences);
  };

  const AccordionLabel = ({ name, description, image }: { name: string; description: string; image?: string }) => (
    <Group >
      {/* <Avatar src={image || DEFAULT_IMAGE} radius="xl" size="lg" /> */}
     
        <Text size="lg" weight={500} mb="md">{name}</Text>
     
    </Group>
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading skills</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Accordion chevronPosition="right" variant="contained">
        {data?.data.map((skill) => (
          <Accordion.Item value={skill.id} key={skill.id}>
            <Accordion.Control>
              <AccordionLabel name={skill.name} description={skill.description} image={skill.image} />
            </Accordion.Control>
            <Accordion.Panel>
              <SkillPreferences
                skillData={skill}
                preferences={preferences[skill.id] || {}}
                onChange={(field, value) => handlePreferenceChange(skill.id, field, value)}
              />
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
      <Group position="right" mt="md">
        <Button variant="outline" onClick={onCancel}>Cancel</Button>
        <Button type="submit">Confirm Installation</Button>
      </Group>
    </form>
  );
};