import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDocumentTitle } from "@refinedev/react-router-v6";

interface TitleContextType {
    documentTitle: string;
    pageHeaderTitle: string;
    setDocumentTitle: (title: string) => void;
    setPageHeaderTitle: (title: string) => void;
}

// Context creation
const TitleContext = createContext<TitleContextType | null>(null);

function isMatchingPath(path: string, segmentSets: any[]) {
    const pathSegments = path.split('/').filter(segment => segment.length > 0);
    return segmentSets.some(segments => {
        if (pathSegments.length < segments.length) return false;
        return segments.every((segment: string, index: number) => pathSegments[index] === segment);
    });
}

// Provider Component
export const TitleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [documentTitle, setDocumentTitle] = useState<string>('');
    const [pageHeaderTitle, setPageHeaderTitle] = useState<string>('');
    const location = useLocation();
    const setTitle = useDocumentTitle();

    useEffect(() => {
        // Set initial document title to blank
        setTitle('');

        const path = location.pathname;
        const desiredSegmentSets = [
            ['apps', 'builder', 'edit'],
            // Add other paths as needed
            ['another', 'path', 'example'],
            ['yet', 'another', 'example']
        ];

        if (!isMatchingPath(path, desiredSegmentSets)) {
          //  console.log('not matching path');
            const pathSegments = location.pathname.split('/').filter(segment => segment.length > 0);
            const newDocumentTitle = `${pathSegments.join(' ')} - zenbot 🤖`;
            const newPageHeaderTitle = pathSegments[pathSegments.length - 1] || 'Home';
            
            // Update state
            setDocumentTitle(newDocumentTitle);
            setPageHeaderTitle(newPageHeaderTitle);
            setTitle(newDocumentTitle);
        } else {
           // console.log('matching path');
            // Update document title based on child component logic
            setTitle(documentTitle+' - zenbot 🤖');
        }
    }, [location.pathname, setTitle, setPageHeaderTitle, setDocumentTitle, documentTitle]);

    // Context value that will be passed to any consumer component
    const contextValue: TitleContextType = {
        documentTitle,
        pageHeaderTitle,
        setDocumentTitle,
        setPageHeaderTitle
    };

    return <TitleContext.Provider value={contextValue}>{children}</TitleContext.Provider>;
};

// Custom Hook for easy context usage
export const useTitles = (): TitleContextType => {
    const context = useContext(TitleContext);
    if (context === null) {
        throw new Error("useTitles must be used within a TitleProvider");
    }
    return context;
};