import dagre from "dagre";
import _ from "lodash";
import { getConnectedEdges, isNode } from "reactflow";

const nodeWidth = 300;
const nodeHeight = 0;
const initialVerticalSpacing = 70;
const subsequentVerticalSpacing = 80;

const getLayoutedElements = (_elements:any) => {
  const elements = _.cloneDeep(_elements);
  const dagreGraph = new dagre.graphlib.Graph();

  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: "TB", ranksep: subsequentVerticalSpacing, nodesep: 100 });
  
  const getEdges = elements.filter((item:any) => item.source);

  elements.forEach((el:any) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, {
        width: el.width || nodeWidth,
        height: (el.height ?? 0) + (nodeHeight ?? 0),
      });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  let startNodeY = 0;

  return elements?.map((el:any) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.position = {
        x: nodeWithPosition.x - (el.width || nodeWidth) / 2,
        y: nodeWithPosition.y - (el.height || nodeHeight) / 2,
      };

      if (el.id === 'start-node' || el.id === 'Request') {
        startNodeY = el.position.y;
      } else {
        el.position.y = startNodeY + initialVerticalSpacing + (el.position.y - startNodeY);
      }
    }
    return el;
  });
};

export { getLayoutedElements };