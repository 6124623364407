import curlToJson from '@bany/curl-to-json';
import { showNotification } from "@mantine/notifications";

interface ResultJSON {
  location?: string;
  form?: string[] | Record<string, string>; // `form` can be an array or an object
  header?: Record<string, string>;
  params?: Record<string, string>;
  data?: Record<string, string>;
  method?: string;
  url?: string; // `url` should be part of ResultJSON
}

interface Values {
  name: string;
  curl_cmd: string;
  curl_json?: ResultJSON;
  headers?: { name: string; value: string }[];
  params?: { name: string; value: string }[];
  url?: string;
  body_data?: { name: string; value: string | unknown }[];
  method?: string;
  error?: boolean;
}

export const transformDataAndSubmit = (values: Values) => {
  console.log(values);
  console.log('transformDataAndSubmit');
  if (values.curl_cmd) {
    try {
      const json: ResultJSON = curlToJson(values.curl_cmd);

      console.log(json);

      values.curl_json = json;
      values.headers = json.header ? Object.entries(json.header).map(([name, value]) => ({ name, value })) : [];
      values.params = json.params ? Object.entries(json.params).map(([name, value]) => ({ name, value })) : [];
      values.method = json.method;
      values.url = json.url || json.location;


      if (json.form) {
        if (Array.isArray(json.form)) {
          values.body_data = json.form.map(str => {
            const [name, value] = str.split('=');
            return { name, value: value.replace(/"/g, '') };
          });
        } else {
          values.body_data = Object.entries(json.form).map(([name, value]) => ({ name, value }));
        }
      } else {
        values.body_data = json.data
          ? Object.entries(json.data).map(([name, value]) => ({ name, value }))
          : [];
      }

      return values;
    } catch (error) {
      console.error('Error while transforming curl command:', error);
      showNotification({
        message: `Something went wrong: ${(error as Error).message}`,
        color: "red",
        autoClose: 7000,
      });
      values.error = true;

      return values;
    }
  } else {
    console.log(values);
    console.log('transformDataAndSubmit');
    return values;
  }
};
