import { useState, useEffect } from 'react';
import { useNavigate, useLocation, NavLink, Outlet } from 'react-router-dom';
import { UnstyledButton, Tooltip, Title, ScrollArea } from '@mantine/core';
import { IconApps, IconDeviceDesktopAnalytics, IconUser, IconHeartHandshake, IconSettings, IconBook } from '@tabler/icons-react';
import { C1 } from './_c1';
import { C2 } from './_c2';
import { C3 } from './_c3';
import { C4 } from './_c4';
import { A1 } from './_a1';
import { A2 } from './_a2';
import { A3 } from './_a3';
import { A4 } from './_a4';
import classes from './_gridLayout.module.css';

const mainLinksMockdata = [
  { icon: IconApps, label: 'Apps', path: '/apps/search' },
  { icon: IconBook, label: 'Docs', path: '/docs/library' },
  { icon: IconDeviceDesktopAnalytics, label: 'Analytics', path: '/analytics/tools' },
  { icon: IconUser, label: 'Account', path: '/account/preferences' },
  { icon: IconHeartHandshake, label: 'Support', path: '/support/videos' },
  { icon: IconSettings, label: 'Settings', path: '/settings/general' },
];

const linksMockdata = [
  { label: 'Search', parent: 'Apps', path: '/apps/search' },
  { label: 'Active', parent: 'Apps', path: '/apps/active' },
  { label: 'Builder', parent: 'Apps', path: '/apps/builder' },
  { label: 'Analytics', parent: 'Analytics', path: '/analytics/tools' },
  { label: 'Support Videos', parent: 'Support', path: '/support/videos' },
  { label: 'Help Center', parent: 'Support', path: '/support/help' },
  { label: 'Preferences', parent: 'Account', path: '/account/preferences' },
  { label: 'Scheduler', parent: 'Apps', path: '/apps/scheduler' },
  { label: 'Chat', parent: 'Apps', path: '/apps/chat' },
  { label: 'Library', parent: 'Docs', path: '/docs/library' },
  { label: 'Builder', parent: 'Docs', path: '/docs/builder' },
  { label: 'Profile', parent: 'Account', path: '/account/profile' },
  { label: 'Logout', parent: 'Account', path: '/logout' },
  { label: 'General', parent: 'Settings', path: '/settings/general' },
  { label: 'Integrations', parent: 'Settings', path: '/settings/integrations' },
  { label: 'Backups', parent: 'Settings', path: '/settings/backups' },
];

export function DoubleNavbar() {
  const [active, setActive] = useState('');
  const [showB2, setShowB2] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const activeParent = getActiveParentFromPath(location.pathname);
    setActive(activeParent);
  }, [location.pathname]);

  function getActiveParentFromPath(currentPath: string) {
    const firstSegment = currentPath.split('/')[1];
    const activeLink = mainLinksMockdata.find(link => link.path.includes(firstSegment));
    return activeLink ? activeLink.label : '';
  }

  const mainLinks = mainLinksMockdata.map((link) => (
    <Tooltip
      label={link.label}
      position="right"
      withArrow
      key={link.label}
    >
      <UnstyledButton
        onClick={() => {
          setActive(link.label);
          setShowB2(true);
        }}
        className={classes.mainLink}
        data-active={link.path.split('/')[1] === location.pathname.split('/')[1] ? "true" : undefined}
      >
        <link.icon style={{ width: '22px', height: '22px' }} stroke={1.5} />
      </UnstyledButton>
    </Tooltip>
  ));

  const handleSubMenuClick = (path: string) => {
    navigate(path);
    setShowB2(false);
  };

  const filteredLinks = linksMockdata.filter(link => link.parent === active);

  const handleAvatarClick = () => {
    setActive('Account');  // Set to Account section
    setShowB2(true);      // Show the submenu
  };

  return (
    <div className={classes.gridContainer}>
      {/* Top row */}
      <A1 />
      <A2 active={active} />
      <A3 />
      <A4 />

      {/* Middle row */}
      <div className={classes.b1}>
        <div className={classes.mainLinks}>
          {mainLinks}
        </div>
      </div>
      <div className={`${classes.b2} ${showB2 ? classes.active : ''}`}>
        <ScrollArea>
          <div className={classes.secondaryNav}>
            {filteredLinks.map((link) => (
              <NavLink
                to={link.path}
                className={({ isActive }) => 
                  isActive ? `${classes.link} ${classes.active}` : classes.link
                }
                key={link.label}
                onClick={() => handleSubMenuClick(link.path)}
              >
                {link.label}
              </NavLink>
            ))}
          </div>
        </ScrollArea>
      </div>
      <div className={classes.b3}>
        <ScrollArea 
          type="auto"
          scrollbarSize={8}
          offsetScrollbars
          scrollHideDelay={500}
          viewportProps={{
            style: { height: '100%' }
          }}
        >
          <div className={classes.contentWrapper}>
            <Outlet />
          </div>
        </ScrollArea>
      </div>
      <div className={classes.b4}></div>

      {/* Bottom row */}
      <C1 onAvatarClick={handleAvatarClick} />
      <C2 />
      <C3 />
      <C4 />
    </div>
  );
}