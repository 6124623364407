import { useReactFlow } from 'reactflow';

function useUpdateNode() {
    const { setNodes, getNodes } = useReactFlow();

    const handleSubmitNode = (data: any, currentNode: any, setNodesState: any) => {
        setNodes((nodes) => {
            const updatedNodes = nodes.map(item => {
                if (currentNode.id === item.id) {
                    return {
                        ...item,
                        data: {
                            ...item.data,
                            ...data,
                            nodeLabel: data.nodeLabel, // Ensure nodeLabel is included
                            isCompleted: true, // Set isCompleted to true when the node is updated
                        }
                    };
                }
                return item;
            });
            
            // Update the nodes state managed by useNodesState
            setNodesState(updatedNodes);
            
            return updatedNodes;
        });
    }

    return { handleSubmitNode };
}

export default useUpdateNode;