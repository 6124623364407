import React, { useEffect } from "react";
import { useTable } from "@refinedev/react-table";
import { ColumnDef, flexRender } from "@tanstack/react-table";
import { List, SaveButton, useModalForm } from "@refinedev/mantine";
import { IconWand } from '@tabler/icons-react';
import { useGetIdentity } from "@refinedev/core";

import { Box, Modal, Group, ScrollArea, Table, Pagination, Select, Button, TextInput, Textarea } from "@mantine/core";

import { ColumnFilter, ColumnSorter } from "../../components/table";
import { FilterElementProps, IApp, ISelectOption, IIdentity } from "../../interfaces";

import { SoftDeleteButtonX, EditButtonX } from "../../components/custom/Basic/Buttons";



export const AppBuilderList: React.FC = () => {
  const {
    getInputProps,
    saveButtonProps,
    modal: { show, close, title, visible },
  } = useModalForm({
    refineCoreProps: { action: "create", redirect: "list" },
    initialValues: {
      name: "",
      description: "",
      status_id: 12,
      acl_id: 10,
    },
    validate: {
      name: (value) => (value.length < 2 ? "Too short title" : null),
    },
  });

  // const handleSubmit = async (values) => {
  //   // Your form submission logic here
  //   const newID = await submitForm(values); // Assume submitForm is a function that handles form submission and returns the new ID

  //   // Custom redirect to 'apps/builder/edit/$(newID)/core'
  //   redirect(`apps/builder/edit/${newID}/core`);
  // };



  const columns = React.useMemo<ColumnDef<IApp>[]>(
    () => {
   
      const statusLabels:ISelectOption[] = [
        { value: "12", label: "draft" },
        { value: "13", label: "archived" },
        { value: "15", label: "published" },
      ];

      const statusLabelMap = statusLabels.reduce((map, obj) => {
        map[obj.value] = obj.label;
        return map;
      }, {} as { [key: string]: string });

      return [
        {
          id: "name",
          header: "Name",
          accessorKey: "name",
          meta: {
            filterOperator: "contains",
          },
        },
        {
          id: "description",
          header: "Description",
          accessorKey: "description",
          meta: {
            filterOperator: "contains",
          },
        },
        {
          id: "status_id",
          header: "Status",
          accessorKey: "status_id",
          cell: ({ getValue }) => {
            const value = getValue() as number; // Assuming getValue() returns a number
            return statusLabelMap[value] || "Unknown";
          },
          meta: {
            filterElement: function render(props: FilterElementProps) {
               return <Select {...props} data={statusLabels} />;
            },
            filterOperator: "eq",
          },
        },
        {
          id: "actions",
          header: "Actions",
          accessorKey: "id",
          enableColumnFilter: false,
          enableSorting: false,
          cell: function render({ getValue }) {
            const appId = getValue() as string;
            return (
              <Group spacing="xs" noWrap style={{ justifyContent: "flex-start" }}>
                <EditButtonX prepath="builder/edit" recordItemId={appId} postpath="core" />
                <SoftDeleteButtonX resource="apps" recordItemId={appId} />
              </Group>
            );
          },
        },
      ];
    },
    []
  );

  const { data: identity } = useGetIdentity<IIdentity>();

  useEffect(() => {
   // console.log("Identity:", identity);
    if (identity) {
      //console.log("Avatar URL:", identity.user_metadata?.avatar_url);
    }
  }, [identity]);

  const user_id = identity?.id;


  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData },
    },
  } = useTable({
    columns,
    refineCoreProps: {
      initialCurrent: 1,
      initialPageSize: 20,
      initialSorter: [
        {
          field: "name",
          order: "asc",
        },
      ],
      syncWithLocation: true,
      filters: {
        permanent: [
            {
                field: "status_id",
                operator: "ne",
                value: -1,
            },
            {
              field: "user_id",
              operator: "eq",
              value:  user_id,
          },
        ],
    },
    },
  });

  React.useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      meta: {
        ...prev.meta,
      },
    }));
  }, [setOptions]);

  const rowStyles = (index: number) => ({
    backgroundColor: index % 2 === 0 ? '#f3f3f3' : '#ffffff', // Alternating row colors
  });

  return (
    <>
      <Modal
        opened={visible}
        onClose={close}
        title={title}
        padding="xl"
        size="xl"
      >
        <TextInput
          mt={8}
          label="Name"
          {...getInputProps('name')}
          placeholder="Name of your App"
        />
        <Textarea
          label="Description"
          {...getInputProps('description')}
          placeholder="Description of the App"
          minRows={4}
        />
        <Box mt={8} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <SaveButton {...saveButtonProps} />
        </Box>
      </Modal>
      <ScrollArea>
        <List
          headerButtons={() => (
            <Button
              leftIcon={<IconWand />}
              onClick={(event) => {
                event.preventDefault();
                show();
              }}
              classNames={{ root: 'gradient_anim_btn' }}
            >
              Add
            </Button>
          )}
        >
          {tableData?.length === 0 ? (
            <div>Loading...</div>
          ) : (
            <Table
              striped
              highlightOnHover
              horizontalSpacing="xl"
              verticalSpacing="md"
              fontSize="md"
            >
              <thead className="tableThead">
                {getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th key={header.id}>
                          {!header.isPlaceholder && (
                            <Group spacing="xs" noWrap>
                              <Box>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                              </Box>
                              <Group spacing="xs" noWrap>
                                <ColumnSorter column={header.column} />
                                <ColumnFilter column={header.column} />
                              </Group>
                            </Group>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id} style={rowStyles(row.index)}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
          <br />
          <Pagination
            position="right"
            total={pageCount}
            page={current}
            onChange={setCurrent}
          />
        </List>
      </ScrollArea>
    </>
  );
};
