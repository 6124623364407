import React from 'react';
import { Card, Image, Text,  Button, Group } from '@mantine/core';

function AccountPreferences() {
  return (
    <div style={{ width: '800px', }}>

      <Card shadow="sm"  radius="md" withBorder>
      <Card.Section component="a" href="https://mantine.dev/">
        <Image
          src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-8.png"
          height={160}
          alt="Norway"
        />
      </Card.Section>

      <Group  mt="md" mb="xs">
        <Text fw={500}>Update your function calling (Apps)</Text>
        
      </Group>

      <Text size="sm" c="dimmed">
        Update the apps available to your Zen Bot by hitting the blue button.
      </Text>

      <Button color="blue" fullWidth mt="md" radius="md">
        Update
      </Button>
    </Card>

    </div>

    
  );
}

export default AccountPreferences;