import { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { DocBuilder } from './DocBuilder';
import { docBuilderService } from './docBuilderService';
import { ContentItem } from './types';

export function DocBuilderContainer() {
  const [items, setItems] = useState<ContentItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadDocuments();
  }, []);

  const loadDocuments = async () => {
    try {
      const data = await docBuilderService.fetchDocuments();
      console.log('Loaded documents:', data);
      setItems(data);
    } catch (error) {
      console.error('Error loading documents:', error);
      showNotification({
        title: 'Error',
        message: 'Failed to load documents',
        color: 'red'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleItemAdd = async (newItem: Omit<ContentItem, 'id'>) => {
    try {
      const created = await docBuilderService.createDocument(newItem);
      setItems(prev => [...prev, created]);
      showNotification({
        title: 'Success',
        message: 'Document created successfully',
        color: 'green'
      });
    } catch (error) {
      showNotification({
        title: 'Error',
        message: 'Failed to create document',
        color: 'red'
      });
    }
  };

  const handleItemUpdate = async (updatedItem: ContentItem) => {
    try {
      await docBuilderService.updateDocument(updatedItem);
      setItems(prev => prev.map(item => 
        item.id === updatedItem.id ? updatedItem : item
      ));
      showNotification({
        title: 'Success',
        message: 'Document updated successfully',
        color: 'green'
      });
    } catch (error) {
      showNotification({
        title: 'Error',
        message: 'Failed to update document',
        color: 'red'
      });
    }
  };

  const handleGeneratePDF = async (itemId: string) => {
    try {
      await docBuilderService.generatePDF(itemId);
      showNotification({
        title: 'Success',
        message: 'PDF generated successfully',
        color: 'green'
      });
    } catch (error) {
      showNotification({
        title: 'Error',
        message: 'Failed to generate PDF',
        color: 'red'
      });
    }
  };

  const handlePublish = async (itemId: string) => {
    try {
      await docBuilderService.publishDocument(itemId);
      showNotification({
        title: 'Success',
        message: 'Document published successfully',
        color: 'green'
      });
    } catch (error) {
      showNotification({
        title: 'Error',
        message: 'Failed to publish document',
        color: 'red'
      });
    }
  };

  return (
    <DocBuilder
      items={items}
      onItemAdd={handleItemAdd}
      onItemUpdate={handleItemUpdate}
      onGeneratePDF={handleGeneratePDF}
      onPublish={handlePublish}
      isLoading={isLoading}
    />
  );
} 