const EachType = (props: any) => {
  const { item, actionHandle, setIsSaved } = props;
  const { label, Icon } = item;
  return (
    <div
      style={{
        width: "100%", // w-full
        height: "80px", // h-[80px]
        padding: "16px", // p-[16px]
        backgroundColor: "#fff", // bg-white
        borderRadius: "0.5rem", // rounded-lg (assuming 0.5rem is the default border-radius for lg in Tailwind)
        border: "1px solid #D1D5DB", // border border-gray-300
        display: "flex", // flex
        alignItems: "center", // items-center
        gap: "1rem", // gap-4 (assuming 1rem is the default gap for gap-4 in Tailwind)
        cursor: "pointer", // cursor-pointer
      }}
      onClick={() => {
        actionHandle(item);
        setIsSaved(true);
      }}
    >
      <div
        style={{
          width: "58px", // w-[58px]
          height: "48px", // h-[48px]
          position: "relative", // relative
        }}
      >
        <div
          style={{
            width: "2rem", // w-8 (assuming 1rem = 16px)
            height: "2rem", // h-8 (assuming 1rem = 16px)
            left: "0", // left-0
            position: "absolute", // absolute
          }}
        >
          <Icon />
        </div>
      </div>
      <div
        style={{
          paddingTop: "3px", // pt-[3px]
          paddingBottom: "3px", // pb-[3px]
          justifyContent: "flex-start", // justify-start
          alignItems: "center", // items-center
          gap: "3rem", // gap-12 (assuming 1rem = 16px)
          display: "flex", // flex
        }}
      >
        <div>
          <div
            style={{
              // width: "151px", // w-[151px]
              color: "#000", // text-black
              fontSize: "1rem", // text-base (assuming 1rem is the default font size for base in Tailwind)
              fontFamily: "Inter, sans-serif", // font-inter
              fontWeight: "400", // font-normal (assuming 400 is the default font weight for normal in Tailwind)
              wordBreak: "break-word", // break-words
            }}
          >
            {label}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EachType;
