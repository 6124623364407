import React, { Dispatch, ReactElement, SetStateAction } from "react";
import { Modal, createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  modal: {
    backgroundColor: theme.white,
  },
}));

type SelectNode = {
  children: ReactElement<any, any>;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedIndex: Dispatch<SetStateAction<number>>;
};

const SelectNodeModal = ({
  isModalOpen,
  setIsModalOpen,
  children,
  setSelectedIndex,
}: SelectNode) => {
  const { classes } = useStyles();

  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedIndex(0);
  };

  return (
    <Modal
      opened={isModalOpen}
      onClose={handleClose}
      title="Choose Step"
      size="lg"
      classNames={{ modal: classes.modal }}
    >
      {children}
    </Modal>
  );
};

export default SelectNodeModal;
