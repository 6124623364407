import React from 'react';

function SettingsBackups() {
  return (
    <div>
      <h1>Settings - Backups</h1>
      <p>Manage your backup settings here.</p>
    </div>
  );
}

export default SettingsBackups;