import EachType from "./EachType";
import { nodeTypeCondtion, nodeTypeStage } from "./Utils";
import { Tabs } from "antd";

const NodeSelectTab = ({
  stepActionHandle,
  conditionActionHandle,
  onlySteps,
  setIsSaved,
  setSelectedIndex,
  selectedIndex,
}: any) => {
  type Item = {
    label: string;
    key: string;
    children: JSX.Element;
  };
  const items: Item[] = [
    {
      label: `Actions`,
      key: "1",
      children: (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "0.5rem",
          }}
        >
          {nodeTypeStage?.map((item, i) => (
            <EachType
              key={i}
              item={item}
              setIsSaved={setIsSaved}
              actionHandle={stepActionHandle}
            />
          ))}
        </div>
      ),
    },
    {
      label: `Condition`,
      key: "2",
      children: (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: "0.5rem",
          }}
        >
          {nodeTypeCondtion?.map((item, i) => (
            <EachType
              key={i}
              item={item}
              setIsSaved={setIsSaved}
              actionHandle={conditionActionHandle}
            />
          ))}
        </div>
      ),
    },
  ];
  const renderedTab = onlySteps ? [...items].slice(0, 1) : items;

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "28rem",
        padding: "0.5rem",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Tabs items={renderedTab} />
    </div>
  );
};
export default NodeSelectTab;
