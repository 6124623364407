import React from 'react';

export const AppSchedulerEdit = () => {
  return (
    <div>
      <h1>App Scheduler Edit</h1>
      <p>...</p>
    </div>
  );
}
