import React, { forwardRef, useImperativeHandle, RefObject } from 'react';
import { TextInput, Textarea, Box, Modal } from "@mantine/core";
import {  SaveButton, useModalForm  } from "@refinedev/mantine";
import { transformDataAndSubmit } from "./transformDataAndSubmit";
//import { error } from 'console';

export const SkillForm = forwardRef<{ openModal: () => void, closeModal: () => void }, { app_id: string }>((props, ref) => {


useImperativeHandle(ref, () => ({
    openModal: show,
    closeModal: close,
  }));


  const {
    getInputProps,
    setFieldError,
    saveButtonProps,
    modal: { show, close, title, visible },
  } = useModalForm({
    refineCoreProps: {
      resource: "skills",
      action: "create",
    },
    initialValues: {
      name: "",
      description: "",
      curl_cmd: "",
      status_id: 12,
      app_id: props.app_id,
    },
    transformValues: (values) => {
      
      const newvalues = transformDataAndSubmit(values);
      if (newvalues.error === true) {
        // Highlight the curl_cmd field
        console.log("bad values", values);
        setFieldError("curl_cmd", "CURL statement supplied can not be parsed.");
  
        return false;
      } else {
        console.log("good values", newvalues);
        return { ...newvalues };
      }
      
    },
 
    validate: {
      name: (value) => (value.length < 2 ? "Too short title" : null),
      description: (value) => (value.length < 10 ? "Too short description" : null),
    },
  });

  

 // const {  errors  } = useModalForm();


  return (
    <>
      <Modal opened={visible} onClose={close} title={title} padding="xl" size="xl">
        <TextInput mt={8} label="Name" {...getInputProps("name")} placeholder="Name of Skill" />
        <Textarea
          label="Description"
          {...getInputProps("description")}
          placeholder="Description of the Skill"
          minRows={4}
        />
        <Textarea
          label="Curl Command"
          {...getInputProps("curl_cmd")}
          placeholder="Curl Command to execute the Skill"
          minRows={4}
        />
        <Box mt={8} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <SaveButton {...saveButtonProps} />
        </Box>
      </Modal>
    </>
  );
});
