import { Box, Text } from '@mantine/core';
import { useGetIdentity } from "@refinedev/core";
import { IIdentity } from "../../../interfaces";
import classes from './_gridLayout.module.css';

export function C2() {
  const { data: identity } = useGetIdentity<IIdentity>();
  const name = identity?.user_metadata?.full_name || identity?.email.split('@')[0];

  return (
    <div className={classes.gridC2}>
      <Box>
        <Text size="sm" weight={500}>
          {name}
        </Text>
        <Text color="dimmed" size="xs">
          {identity?.email}
        </Text>
      </Box>
    </div>
  );
} 