import React, { useRef, useCallback } from 'react';
import { useTable, UseTableProps} from "@refinedev/react-table";
import { flexRender, ColumnDef } from "@tanstack/react-table";
import { List } from "@refinedev/mantine";
import { Box, Group, ScrollArea, Table, Pagination, Button, Select } from "@mantine/core";
import { IconWand } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { FilterElementProps, ISkill, ISelectOption } from "../../../../interfaces";
import { ColumnFilter, ColumnSorter } from "../../../table";
import { SkillForm } from "./skillform";
import { SoftDeleteButtonX, EditButtonX } from "../../Basic/Buttons";

export const TabSkills: React.FC = () => {

  const { id: app_id } = useParams<{ id: string }>();

  //table config
  const memoizedEditCellRender = useCallback(({ getValue }: { getValue: () => string }) => {
    const skillId = getValue();
    const prepath = `/apps/builder/edit/${app_id}/skills`;
    const postpath = '';
    return (
      <Group spacing="xs" noWrap>
        <EditButtonX prepath={prepath} recordItemId={skillId} postpath={postpath} />
        <SoftDeleteButtonX resource="skills" recordItemId={skillId} />
      </Group>
    );
  }, [app_id]);

  const columns = React.useMemo<ColumnDef<ISkill>[]>(() => {
    const statusLabels: ISelectOption[] = [
      { value: "12", label: "draft" },
      { value: "13", label: "archived" },
      { value: "15", label: "published" },
    ];

    const statusLabelMap = statusLabels.reduce((map, obj) => {
      map[obj.value] = obj.label;
      return map;
    }, {} as { [key: string]: string });

    return [
      {
        id: "name",
        header: "Name",
        accessorKey: "name",
        meta: {
          filterOperator: "contains",
        },
      },
      {
        id: "description",
        header: "Description",
        accessorKey: "description",
        meta: {
          filterOperator: "contains",
        },
      },
      {
        id: "status_id",
        header: "Status",
        accessorKey: "status_id",
        cell: ({ getValue }) => {
          const value = getValue<number>();
          return statusLabelMap[value] || "Unknown";
        },
        meta: {
          filterElement: function render(props: FilterElementProps) {
            return <Select {...props} data={statusLabels} />;
          },
          filterOperator: "eq",
        },
      },
      {
        id: "actions",
        header: "Actions",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: memoizedEditCellRender
      },
    ] as ColumnDef<ISkill>[];
  }, [memoizedEditCellRender]);

  //data config
  const {
    getHeaderGroups,
    getRowModel,
    setOptions,
    refineCore: {
      setCurrent,
      pageCount,
      current,
      tableQueryResult: { data: tableData },
    },
  } = useTable<ISkill>({
    columns,
    refineCoreProps: {
      initialCurrent: 1,
      initialPageSize: 10,
      resource: "skills",
      filters: {
        permanent: [
          {
            field: "app_id",
            operator: "eq",
            value: app_id,
          },
          {
            field: "status_id",
            operator: "ne",
            value: -1,
          },
        ],
      },
      initialSorter: [
        {
          field: "name",
          order: "asc",
        },
      ],
      syncWithLocation: true,
    },
  } as UseTableProps<ISkill>);

  setOptions((prev) => ({
    ...prev,
    meta: {
      ...prev.meta,
    },
  }));

  //table style
  const rowStyles = useCallback((index: number) => ({
    backgroundColor: index % 2 === 0 ? '#f3f3f3' : '#ffffff',
  }), []);

  const openModalForm = useRef<{ openModal: () => void; closeModal: () => void; } | null>(null);

  const handleOpenModal = () => {
    openModalForm.current?.openModal();
  };

  if (!app_id) return null;

  return (
    <>
      <SkillForm ref={openModalForm} app_id={app_id} />
      <ScrollArea>
        <List
          headerButtons={() => (
            <Button
              leftIcon={<IconWand />}
              onClick={handleOpenModal}
              classNames={{ root: 'gradient_anim_btn' }}
            >
              Add
            </Button>
          )}
        >
          {tableData?.length === 0 ? (
            <div>Loading...</div>
          ) : (
            <Table striped highlightOnHover horizontalSpacing="xl" verticalSpacing="md" fontSize="md">
              <thead className="tableThead">
                {getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th key={header.id}>
                          {!header.isPlaceholder && (
                            <Group spacing="xs" noWrap>
                              <Box>
                                {flexRender(header.column.columnDef.header, header.getContext())}
                              </Box>
                              <Group spacing="xs" noWrap>
                                <ColumnSorter column={header.column} />
                                <ColumnFilter column={header.column} />
                              </Group>
                            </Group>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id} style={rowStyles(row.index)}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
          <br />
          <Pagination position="right" total={pageCount} page={current} onChange={setCurrent} />
        </List>
      </ScrollArea>
    </>
  );
};
