import { useState, useEffect } from 'react';
import { 
  Paper, 
  Container, 
  Tabs, 
  TextInput, 
  Group, 
  Button, 
  Table,
  Select,
  Breadcrumbs,
  Anchor,
  Stack,
  Text,
  Checkbox,
  Title,
  Divider,
  Textarea,
  NumberInput
} from '@mantine/core';
import { IconPlus, IconSearch, IconEdit } from '@tabler/icons-react';
import { ContentItem, PropertyType, DocBuilderProps, ContentItemProperty } from './types';
import TipTapEditor from './TipTapEditor';

const PROPERTY_TYPES: PropertyType[] = [
  'text',
  'longtext',
  'number',
  'date',
  'boolean',
  'select'
];

type CorePropertyLabels = {
  singular: string;
  plural: string;
};

export function DocBuilder({ 
  items,
  onItemAdd,
  onItemUpdate,
  onGeneratePDF,
  onPublish,
  isLoading = false
}: DocBuilderProps) {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string>('list');
  const [filterText, setFilterText] = useState('');
  const [newPropertyLabel, setNewPropertyLabel] = useState('');
  const [newPropertyType, setNewPropertyType] = useState<PropertyType>('text');
  const [isEditing, setIsEditing] = useState(false);
  const [itemName, setItemName] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [hasChildren, setHasChildren] = useState(false);
  const [childLabels, setChildLabels] = useState<CorePropertyLabels>({
    singular: '',
    plural: ''
  });

  const getChildren = (parentId: string | null) => {
    console.log('getChildren called with parentId:', parentId);
    console.log('All items:', items);
    
    const children = items.filter(item => {
      console.log('Checking item:', item);
      console.log('Item parent_id:', item.parent_id);
      
      if (parentId === null) {
        const isRoot = item.parent_id === null;
        console.log('Checking for root item:', isRoot);
        return isRoot;
      } else {
        const isChild = item.parent_id === parentId;
        console.log('Checking for child of', parentId, ':', isChild);
        return isChild;
      }
    });
    
    console.log('Found children:', children);
    return children;
  };

  const getItem = (id: string | null) => {
    return id ? items.find(item => item.id === id) : null;
  };

  const getBreadcrumbPath = (id: string | null): ContentItem[] => {
    const path: ContentItem[] = [];
    let current = getItem(id);
    
    while (current) {
      path.unshift(current);
      current = getItem(current.parent_id);
    }
    
    return path;
  };

  const handleItemClick = (item: ContentItem) => {
    setSelectedId(item.id);
    setActiveTab(item.properties?.hasChildren ? 'list' : 'preview');
  };

  const handleAddProperty = () => {
    if (!newPropertyLabel.trim()) return;
    
    const selected = getItem(selectedId);
    if (!selected) return;

    const newProperty = {
      key: newPropertyLabel.toLowerCase().replace(/\s+/g, '_'),
      type: newPropertyType,
      label: newPropertyLabel,
      required: false,
      name: newPropertyLabel
    };

    const updatedItem = {
      ...selected,
      properties: {
        ...selected.properties,
        childProperties: [
          ...(selected.properties?.childProperties || []),
          newProperty
        ]
      }
    };

    onItemUpdate(updatedItem);
    setNewPropertyLabel('');
    setNewPropertyType('text');
  };

  const handleDeleteProperty = (propertyKey: string) => {
    const selected = getItem(selectedId);
    if (!selected) return;

    const childProperties = selected.properties?.childProperties || [];
    
    const updatedItem = {
      ...selected,
      properties: {
        ...selected.properties,
        childProperties: childProperties.filter(
          prop => prop.key !== propertyKey
        )
      }
    };

    onItemUpdate(updatedItem);
  };

  const selected = getItem(selectedId);
  const currentChildren = getChildren(selectedId);

  const handleGeneratePDF = async () => {
    if (!selectedId || !onGeneratePDF) return;
    await onGeneratePDF(selectedId);
  };

  const handlePublish = async () => {
    if (!selectedId || !onPublish) return;
    await onPublish(selectedId);
  };

  const handleContentUpdate = (newContent: string) => {
    if (!selected) return;
    
    const updatedItem = {
      ...selected,
      content: newContent
    };
    
    onItemUpdate(updatedItem);
    setIsEditing(false);
  };

  useEffect(() => {
    if (selected) {
      setItemName(selected.name);
      setItemDescription(selected.description || '');
      setHasChildren(selected.properties?.hasChildren || false);
      setChildLabels({
        singular: selected.properties?.childLabels?.singular || '',
        plural: selected.properties?.childLabels?.plural || ''
      });
    }
  }, [selected]);

  const handleCorePropertyUpdate = () => {
    if (!selected) return;

    const updatedItem = {
      ...selected,
      name: itemName,
      description: itemDescription,
      properties: {
        ...selected.properties,
        hasChildren
      }
    };

    onItemUpdate(updatedItem);
  };

  const handleChildLabelsUpdate = () => {
    if (!selected) return;

    const updatedItem = {
      ...selected,
      properties: {
        ...selected.properties,
        childLabels: childLabels
      }
    };

    onItemUpdate(updatedItem);
  };

  console.log('Current items:', items);
  console.log('Current children:', currentChildren);

  const getInheritedProperties = (item: ContentItem | null): ContentItemProperty[] => {
    if (!item?.parent_id) return [];
    const parent = getItem(item.parent_id);
    return parent?.properties?.childProperties || [];
  };

  const renderCustomProperties = (item: ContentItem) => {
    const inheritedProps = getInheritedProperties(item);
    if (inheritedProps.length === 0) return null;

    return (
      <>
        <Divider />
        <div>
          <Title order={4} mb="sm">Custom Properties</Title>
          <Table>
            <thead>
              <tr>
                <th>Label</th>
                <th>Type</th>
                <th>Required</th>
              </tr>
            </thead>
            <tbody>
              {inheritedProps.map(prop => (
                <tr key={prop.key}>
                  <td>{prop.label}</td>
                  <td>{prop.type}</td>
                  <td>{prop.required ? 'Yes' : 'No'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  };

  const getCorePropertyLabels = (item: ContentItem | null): {nameLabel: string, descLabel: string} => {
    if (!item?.parent_id) return { nameLabel: 'Name', descLabel: 'Description' };
    
    const parent = getItem(item.parent_id);
    const singular = parent?.properties?.childLabels?.singular || '';
    
    return {
      nameLabel: singular ? `${singular} Name` : 'Name',
      descLabel: singular ? `${singular} Description` : 'Description'
    };
  };

  const propertyLabels = getCorePropertyLabels(selected || null);

  const renderPropertyInput = (prop: ContentItemProperty) => {
    const value = selected?.properties?.[prop.key] || '';
    
    const handleChange = (newValue: any) => {
      if (!selected) return;
      
      const updatedItem = {
        ...selected,
        properties: {
          ...selected.properties,
          [prop.key]: newValue
        }
      };
      onItemUpdate(updatedItem);
    };

    switch (prop.type) {
      case 'date':
        return (
          <TextInput
            type="date"
            label={prop.label}
            value={value ? value.split('T')[0] : ''}
            onChange={(e) => handleChange(e.target.value)}
            required={prop.required}
          />
        );
      case 'boolean':
        return (
          <Checkbox
            label={prop.label}
            checked={!!value}
            onChange={(e) => handleChange(e.currentTarget.checked)}
          />
        );
      case 'longtext':
        return (
          <Textarea
            label={prop.label}
            value={value}
            onChange={(e) => handleChange(e.currentTarget.value)}
            required={prop.required}
            minRows={3}
          />
        );
      case 'number':
        return (
          <NumberInput
            label={prop.label}
            value={value}
            onChange={(val) => handleChange(val)}
            required={prop.required}
          />
        );
      default:
        return (
          <TextInput
            label={prop.label}
            value={value}
            onChange={(e) => handleChange(e.currentTarget.value)}
            required={prop.required}
          />
        );
    }
  };

  return (
    <Container size="xl">
      <Paper shadow="sm" p="xl" withBorder>
        {/* Top Bar */}
        <Group position="apart" mb="md">
          <Breadcrumbs>
            <Anchor 
              onClick={() => setSelectedId(null)}
              underline={true}
            >
              Root
            </Anchor>
            {getBreadcrumbPath(selectedId).map((item) => (
              <Anchor 
                key={item.id}
                onClick={() => handleItemClick(item)}
                underline={true}
              >
                {item.name}
              </Anchor>
            ))}
          </Breadcrumbs>
          <TextInput
            placeholder="Filter items..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            icon={<IconSearch size={16} />}
          />
        </Group>

        {/* Tabs */}
        <Tabs value={activeTab} onTabChange={(value) => setActiveTab(value || 'list')}>
          <Tabs.List>
            {(!selected || selected.properties?.hasChildren) && (
              <Tabs.Tab value="list">List</Tabs.Tab>
            )}
            <Tabs.Tab value="preview">Preview</Tabs.Tab>
            <Tabs.Tab value="properties">Properties</Tabs.Tab>
            <Tabs.Tab value="options">Options</Tabs.Tab>
          </Tabs.List>

          {(!selected || selected.properties?.hasChildren) && (
            <Tabs.Panel value="list">
              <Stack mt="md">
                {selected?.properties?.childLabels?.plural && (
                  <Title order={3}>{selected.properties.childLabels.plural}</Title>
                )}
                {currentChildren.length > 0 ? (
                  currentChildren
                    .filter(item => 
                      item.name.toLowerCase().includes(filterText.toLowerCase())
                    )
                    .map(item => (
                      <Button
                        key={item.id}
                        variant="light"
                        onClick={() => handleItemClick(item)}
                        fullWidth
                      >
                        {item.name}
                      </Button>
                    ))
                ) : (
                  <Text c="dimmed" ta="center" py="xl">
                    No items found. {selectedId ? 'Add a new item to get started.' : 'This folder is empty.'}
                  </Text>
                )}
                <Button 
                  fullWidth
                  leftIcon={<IconPlus size={16} />}
                  variant="light"
                  onClick={() => {/* TODO: Implement add item */}}
                >
                  Add {selected?.properties?.childLabels?.singular || 'Item'}
                </Button>
              </Stack>
            </Tabs.Panel>
          )}

          <Tabs.Panel value="preview">
            <Group position="right" mb="md">
              <Button
                variant="subtle"
                leftIcon={<IconEdit size={16} />}
                onClick={() => setIsEditing(!isEditing)}
              >
                {isEditing ? 'Cancel' : 'Edit'}
              </Button>
            </Group>
            
            {isEditing ? (
              <TipTapEditor
                content={selected?.content || ''}
                onUpdate={handleContentUpdate}
              />
            ) : (
              <div 
                dangerouslySetInnerHTML={{ __html: selected?.content || '' }}
              />
            )}
          </Tabs.Panel>

          <Tabs.Panel value="properties">
            {selected && (
              <Stack spacing="md">
                <div>
                  <Title order={4} mb="sm">Core Properties</Title>
                  <Stack spacing="xs">
                    <TextInput
                      label={propertyLabels.nameLabel}
                      value={itemName}
                      onChange={(e) => setItemName(e.target.value)}
                      onBlur={handleCorePropertyUpdate}
                    />
                    <TextInput
                      label={propertyLabels.descLabel}
                      value={itemDescription}
                      onChange={(e) => setItemDescription(e.target.value)}
                      onBlur={handleCorePropertyUpdate}
                    />
                  </Stack>
                </div>

                {selected.parent_id && getInheritedProperties(selected).length > 0 && (
                  <>
                    <Divider />
                    <div>
                      <Title order={4} mb="sm">Custom Properties</Title>
                      <Stack spacing="xs">
                        {getInheritedProperties(selected).map(prop => (
                          <div key={prop.key}>
                            {renderPropertyInput(prop)}
                          </div>
                        ))}
                      </Stack>
                    </div>
                  </>
                )}

                <Divider />
                <div>
                  <Checkbox
                    label="Has Children"
                    checked={hasChildren}
                    onChange={(e) => {
                      const newValue = e.currentTarget.checked;
                      setHasChildren(newValue);
                      const updatedItem = {
                        ...selected,
                        properties: {
                          ...selected.properties,
                          hasChildren: newValue
                        }
                      };
                      onItemUpdate(updatedItem);
                    }}
                  />
                </div>

                {hasChildren && (
                  <>
                    <Divider />
                    <div>
                      <Title order={4} mb="sm">Child Type Labels</Title>
                      <Group grow>
                        <TextInput
                          label="Singular (e.g., Policy)"
                          value={childLabels.singular}
                          onChange={(e) => setChildLabels({
                            ...childLabels,
                            singular: e.target.value
                          })}
                          onBlur={handleChildLabelsUpdate}
                        />
                        <TextInput
                          label="Plural (e.g., Policies)"
                          value={childLabels.plural}
                          onChange={(e) => setChildLabels({
                            ...childLabels,
                            plural: e.target.value
                          })}
                          onBlur={handleChildLabelsUpdate}
                        />
                      </Group>
                    </div>

                    <Divider />
                    <div>
                      <Title order={4} mb="sm">Child Properties</Title>
                      <Table>
                        <thead>
                          <tr>
                            <th>Label</th>
                            <th>Type</th>
                            <th>Required</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(selected.properties?.childProperties || []).map(prop => (
                            <tr key={prop.key}>
                              <td>{prop.label}</td>
                              <td>{prop.type}</td>
                              <td>{prop.required ? 'Yes' : 'No'}</td>
                              <td>
                                <Button
                                  color="red"
                                  variant="subtle"
                                  onClick={() => handleDeleteProperty(prop.key)}
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td>
                              <TextInput
                                value={newPropertyLabel}
                                onChange={(e) => setNewPropertyLabel(e.target.value)}
                                placeholder="New property label"
                              />
                            </td>
                            <td>
                              <Select
                                value={newPropertyType}
                                onChange={(value) => setNewPropertyType(value as PropertyType)}
                                data={PROPERTY_TYPES.map(type => ({
                                  value: type,
                                  label: type.charAt(0).toUpperCase() + type.slice(1)
                                }))}
                              />
                            </td>
                            <td></td>
                            <td>
                              <Button
                                rightIcon={<IconPlus size={16} />}
                                onClick={handleAddProperty}
                              >
                                Add
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </>
                )}
              </Stack>
            )}
          </Tabs.Panel>

          <Tabs.Panel value="options">
            <Text>Options content will go here</Text>
          </Tabs.Panel>
        </Tabs>
        <Group position="right" mt="md">
          {onGeneratePDF && (
            <Button onClick={handleGeneratePDF}>
              Generate PDF
            </Button>
          )}
          {onPublish && (
            <Button onClick={handlePublish}>
              Publish
            </Button>
          )}
        </Group>
      </Paper>
    </Container>
  );
} 