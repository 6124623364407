import React from 'react';
import { Tabs } from '@mantine/core';
import { IconPhoto, IconMessageCircle, IconSettings } from '@tabler/icons-react';

export function  AppBuilderCreate() {
  return (
    <div>
    <Tabs defaultValue="gallery">
    <Tabs.List>
      <Tabs.Tab value="core" icon={<IconPhoto size={14} />}>Core</Tabs.Tab>
      <Tabs.Tab value="skills" icon={<IconMessageCircle size={14} />}>Skills</Tabs.Tab>
      <Tabs.Tab value="builder" icon={<IconSettings size={14} />}>App Builder</Tabs.Tab>
      <Tabs.Tab value="shortcuts" icon={<IconSettings size={14} />}>Shortcuts</Tabs.Tab>
      <Tabs.Tab value="tests" icon={<IconSettings size={14} />}>Tests</Tabs.Tab>
    </Tabs.List>

    <Tabs.Panel value="core" pt="xs">
      Core tab content
    </Tabs.Panel>

    <Tabs.Panel value="skills" pt="xs">
      Skills tab content
    </Tabs.Panel>

    <Tabs.Panel value="builder" pt="xs">
      Builder tab content
    </Tabs.Panel>

    <Tabs.Panel value="shortcuts" pt="xs">
      Shortcuts tab content
    </Tabs.Panel>

    <Tabs.Panel value="tests" pt="xs">
      Tests tab content
    </Tabs.Panel>
  </Tabs>
  </div>
  );
}