import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

const TimerHelper = ({
  counter,
  decrementCounter,
  handler,
  incrementCounter,
  suspectedId,
}: any) => {
  const minCount = 1; // Minimum value
  const maxCount = 365; // Maximum value
  return (
    <div
      style={{
        zIndex: 1000, // z-1000
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // shadow-md
        borderRadius: "0.375rem", // rounded-sm (assuming 0.375rem is the default border-radius for sm in Tailwind)
        marginTop: "0.5rem", // mt-2 (assuming 1rem = 16px)
        position: "absolute", // absolute
        width: "360px", // w-[360px]
      }}
    >
      <div
        style={{
          display: "flex", // flex
          backgroundColor: "#fff", // bg-white
          justifyContent: "center", // justify-center
          gap: "0.25rem", // gap-1 (assuming 0.25rem is the default gap for gap-1 in Tailwind)
          alignItems: "center", // items-center
        }}
      >
        <p
          style={{
            color: "#000", // text-black
            fontSize: "0.875rem",
          }}
        >
          Wait
        </p>
        <div
          style={{
            display: "flex", // flex
            justifyContent: "center", // justify-center
            border: "1px solid #000", // border
            borderRadius: "0.375rem", // rounded-lg (assuming 0.375rem is the default border-radius for lg in Tailwind)
            margin: "0.25rem 0",
          }}
        >
          <button
            style={{
              color: "#000", // text-black
              borderRadius: "0", // rounded-none
              paddingLeft: "0.5rem", // px-2 (assuming 0.5rem is the default padding for px-2 in Tailwind)
              paddingRight: "0.5rem",
            }}
            // className={`text-black rounded-none px-2 ${
            //   counter === 1 ? "cursor-not-allowed bg-[#f3f6f9]" : "bg-white"
            // } `}
            onClick={() => decrementCounter(suspectedId)}
            disabled={counter === 1 && true}
          >
            <MinusCircleOutlined
              style={{
                height: "1.25rem", // h-5 (assuming 1rem = 16px)
                width: "1.25rem", // w-5 (assuming 1rem = 16px)
                flexShrink: 0, // flex-shrink-0
                color: "#000", // text-black
              }}
              // className="h-5 w-5 flex-shrink-0 text-black"
            />
          </button>

          <input
            type="number"
            value={counter}
            onChange={(e) => handler(e, suspectedId)}
            style={{
              width: "100px", // w-[100px]
              // appearance: "textfield", // [appearance:textfield]
              // "::-webkit-outer-spin-button": { appearance: "none" }, // [&::-webkit-outer-spin-button]:appearance-none
              // "::-webkit-inner-spin-button": { appearance: "none" }, // [&::-webkit-inner-spin-button]:appearance-none
              // "::-moz-appearance": { appearance: "textfield" }, // [nopan]
              padding: "1.5rem 2.5rem", // px-6 py-4 (assuming 1rem = 16px)
              fontWeight: "600", // font-semibold
              color: "red", // text-[red]
              border: "1px solid #e2e8f0", // border border-gray-200
              outline: "none", // outline-none
              // "&:hover": { borderColor: "#9f7aea" }, // hover:border-violet-400
              // "&:focus": { outlineColor: "#9f7aea" },
            }}
            // className={`w-[100px] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none nopan px-6 py-4 font-semibold text-[red]  border border-gray-200 outline-none hover:border-violet-400 focus:outline-none`}
            min={minCount}
            max={maxCount}
          />
          <button
            className={`text-black px-2 ${
              counter === 365
                ? "cursor-not-allowed rounded-none  bg-[#f3f6f9]"
                : "bg-white"
            } `}
            onClick={() => incrementCounter(suspectedId)}
          >
            <PlusCircleOutlined className="h-5 w-5 flex-shrink-0 text-black" />
          </button>
        </div>
        <p className="text-black text-sm">{counter === 1 ? "Day" : "Days"}</p>
      </div>
    </div>
  );
};

export default TimerHelper;
