import { useOne, useList, HttpError } from "@refinedev/core";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Tabs, LoadingOverlay } from '@mantine/core';
import { IconPhoto, IconMessageCircle, IconSettings } from '@tabler/icons-react';

import { ISkills, IAppFlow } from "../../interfaces";

import { AppEdit } from '../../components/custom/AppBuilder/tabCore';
import { TabSkills } from '../../components/custom/AppBuilder/tabSkills/tabSkills';

import AppFlowBuilder from "../../components/custom/AppBuilder/tabAppFlowBuilder/appFlowBuilder";
import ContextProvider from "../../contexts/flowProvider";
import { ReactFlowProvider } from "reactflow";

export function AppBuilderEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const { tabValue } = useParams();

  const handleTabChange = (value: string) => {
    const urlSegments = location.pathname.split('/');
    urlSegments[urlSegments.length - 1] = value;
    const newPath = urlSegments.join('/');
    navigate(newPath);
  };

  const { id } = useParams();

  const { data: isAppData, isLoading: appLoading, isError: appError } = useOne<IAppFlow, HttpError>({
    id,
  });

  const appData = isAppData?.data;

  const { data: isSkillData, isLoading: skillsLoading, isError: skillsError } = useList<ISkills, HttpError>({
    resource: "skills",
    meta: {
      select: "id, name",
      sort: [{ field: "name", order: "asc" },]
    },
    filters: [{ field: "app_id", operator: "eq", value: id }],
  });

  const skillData = isSkillData?.data;

  if (appLoading || skillsLoading) {
    return <LoadingOverlay visible={true} />;
  }

  if (appError || skillsError) {
    return <div>Error occurred</div>;
  }

  return (
    <div>
      <Tabs value={tabValue} onTabChange={handleTabChange}>
        <Tabs.List>
          <Tabs.Tab value="core" icon={<IconPhoto size={14} />}>Core</Tabs.Tab>
          <Tabs.Tab value="skills" icon={<IconMessageCircle size={14} />}>Skills</Tabs.Tab>
          <Tabs.Tab value="builder" icon={<IconSettings size={14} />}>App Builder</Tabs.Tab>
          <Tabs.Tab value="shortcuts" icon={<IconSettings size={14} />}>Shortcuts</Tabs.Tab>
          <Tabs.Tab value="tests" icon={<IconSettings size={14} />}>Tests</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="core" pt="xs">
          <AppEdit />
        </Tabs.Panel>

        <Tabs.Panel value="skills" pt="xs">
          <TabSkills />
        </Tabs.Panel>

        {appData && tabValue === "builder" && (
          <Tabs.Panel value="builder" pt="xs">
            <ContextProvider>
              <ReactFlowProvider>
                <AppFlowBuilder
                  name={appData.name}
                  id={appData.id}
                  json_store={appData.json_store}
                  skills={skillData}
                />
              </ReactFlowProvider>
            </ContextProvider>
          </Tabs.Panel>
        )}

        <Tabs.Panel value="shortcuts" pt="xs">
          Shortcuts tab content
        </Tabs.Panel>

        <Tabs.Panel value="tests" pt="xs">
          Tests tab content
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}