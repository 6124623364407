import React, { Fragment, useContext, useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Transition } from "@headlessui/react";
import useNodeDelete from "../../../../hooks/reactflow/useNodeDelete";
import useUpdateNode from "../../../../hooks/reactflow/useUpdateNode";
import { GlobalContext } from "../../../../contexts/flowProvider";
import { useReactFlow } from "reactflow";
import { useList } from "@refinedev/core";
import { 
  TextInput, 
  Select, 
  Textarea, 
  Button, 
  Stack, 
  Text, 
  Box,
  Popover,
  Group,
  ScrollArea
} from '@mantine/core';

const SideBar = ({ sideBarOpen, currentNode, setIsSaved, setNodes }: any) => {
  const { handleSubmitNode } = useUpdateNode();
  const { handleDelete } = useNodeDelete();
  const { getNodes } = useReactFlow();
  const { state, dispatch } = useContext<any>(GlobalContext);
  const { id: appId } = useParams<{ id: string }>();
  const [formValues, setFormValues] = useState<any>({});

  const { data: skillsData, isLoading: isLoadingSkills } = useList({
    resource: "skills",
    filters: [
      {
        field: "app_id",
        operator: "eq",
        value: appId,
      },
      {
        field: "status_id",
        operator: "eq",
        value: 15,
      },
    ],
    sorters: [
      {
        field: "name",
        order: "asc",
      },
    ],
  });

  const updateFormFields = useCallback(() => {
    if (currentNode && currentNode.data) {
      setFormValues({
        skills: currentNode.data.skills,
        llm_api: currentNode.data.llm_api,
        prompt: currentNode.data.prompt,
        node: currentNode.data.node,
        endpoint: currentNode.data.endpoint,
        selectedExample: currentNode.data.selectedExample,
        responseType: currentNode.data.responseType,
        responseContent: currentNode.data.responseContent,
        requiredField: currentNode.data.requiredField,
        errorMessage: currentNode.data.errorMessage,
        paramValue: currentNode.data.paramValue,
        criteria: currentNode.data.criteria,
      });
    }
  }, [currentNode]);

  useEffect(() => {
    updateFormFields();
  }, [currentNode, updateFormFields]);

  const onFinish = () => {
    let nodeLabel = "";
    let isCompleted = true;

    switch (currentNode.data.actionTitle) {
      case "api_skills":
        const selectedSkill = skillsData?.data.find((skill: any) => skill.id === formValues.skills);
        if (selectedSkill) {
          nodeLabel = selectedSkill.name;
        }
        break;
      case "required_field_missing":
        nodeLabel = `Required: ${formValues.requiredField}`;
        break;
      case "param_value_match_criteria":
        nodeLabel = `Criteria: ${formValues.paramValue}`;
        break;
      default:
        nodeLabel = formValues.node || formValues.selectedExample || "";
    }

    let updatedData = { 
      ...currentNode.data, 
      ...formValues,
      nodeLabel,
      isCompleted,
    };

    handleSubmitNode(updatedData, currentNode, setNodes);
    
    dispatch({
      type: "NODE_SIDE_BAR",
      payload: {
        open: false,
        activeId: "",
      },
    });
    setIsSaved(true);
  };

  const handleDeleteNode = () => {
    if (currentNode && currentNode.id) {
      handleDelete(currentNode.id);
      dispatch({
        type: "NODE_SIDE_BAR",
        payload: {
          open: false,
          activeId: "",
        },
      });
      setIsSaved(true);
    }
  };

  const handleInputChange = (name: string, value: any) => {
    setFormValues((prev: any) => ({ ...prev, [name]: value }));
  };

  const renderNodeForm = () => {
    switch (currentNode.data.actionTitle) {
      case "start":
        return (
          <Select
            label="Select Example"
            placeholder="Select an example"
            data={[
              { value: 'Example 1', label: 'Example 1' },
              { value: 'Example 2', label: 'Example 2' },
              { value: 'Example 3', label: 'Example 3' },
            ]}
            value={formValues.selectedExample}
            onChange={(value) => handleInputChange('selectedExample', value)}
            required
          />
        );
      case "end":
        return (
          <>
            <Select
              label="Response Type"
              placeholder="Select response type"
              data={[
                { value: 'text', label: 'Text' },
                { value: 'image', label: 'Image' },
                { value: 'audio', label: 'Audio' },
              ]}
              value={formValues.responseType}
              onChange={(value) => handleInputChange('responseType', value)}
              required
            />
            <Textarea
              label="Response Content"
              placeholder="Enter response content or prompt"
              minRows={3}
              maxRows={5}
              value={formValues.responseContent}
              onChange={(event) => handleInputChange('responseContent', event.currentTarget.value)}
              required
            />
          </>
        );
      case "api_skills":
        return (
          <Select
            label="Skills"
            placeholder="Select a skill"
            data={skillsData?.data.map((skill: any) => ({
              value: skill.id,
              label: skill.name,
            })) || []}
            value={formValues.skills}
            onChange={(value) => handleInputChange('skills', value)}
            searchable
            nothingFound="No options"
            required
          />
        );
      case "llm_api":
        return (
          <>
            <Select
              label="Select LLM API"
              placeholder="Select LLM API"
              data={[
                { value: "gpt-3.5", label: "openAI GPT-3.5" },
                { value: "gpt-4-5", label: "openAI GPT-4.5" },
              ]}
              value={formValues.llm_api}
              onChange={(value) => handleInputChange('llm_api', value)}
              required
            />
            <Select
              label="Nodes"
              placeholder="Select a node"
              data={getNodes().map((item: any) => ({
                value: item.id,
                label: item.data?.label,
              }))}
              value={formValues.node}
              onChange={(value) => handleInputChange('node', value)}
              required
            />
            <Textarea
              label="System prompt"
              placeholder="Enter system prompt"
              minRows={3}
              maxRows={5}
              value={formValues.prompt}
              onChange={(event) => handleInputChange('prompt', event.currentTarget.value)}
              required
            />
          </>
        );
      case "await_task_completed":
        return (
          <>
            <Select
              label="Endpoints"
              placeholder="Select an endpoint"
              data={[]} // You may want to populate this with actual endpoint options
              value={formValues.endpoint}
              onChange={(value) => handleInputChange('endpoint', value)}
              required
            />
            <TextInput
              label="Nodes"
              placeholder="cron expression"
              value={formValues.node}
              onChange={(event) => handleInputChange('node', event.currentTarget.value)}
              required
            />
            <Textarea
              label="System prompt"
              placeholder="Enter system prompt"
              minRows={3}
              maxRows={5}
              value={formValues.prompt}
              onChange={(event) => handleInputChange('prompt', event.currentTarget.value)}
              required
            />
          </>
        );
      case "required_field_missing":
        return (
          <>
            <TextInput
              label="Required Field"
              placeholder="Enter required field"
              value={formValues.requiredField}
              onChange={(event) => handleInputChange('requiredField', event.currentTarget.value)}
              required
            />
            <Textarea
              label="Error Message"
              placeholder="Enter error message"
              minRows={3}
              maxRows={5}
              value={formValues.errorMessage}
              onChange={(event) => handleInputChange('errorMessage', event.currentTarget.value)}
              required
            />
          </>
        );
      case "param_value_match_criteria":
        return (
          <>
            <TextInput
              label="Parameter Value"
              placeholder="Enter parameter value"
              value={formValues.paramValue}
              onChange={(event) => handleInputChange('paramValue', event.currentTarget.value)}
              required
            />
            <Textarea
              label="Criteria"
              placeholder="Enter criteria"
              minRows={3}
              maxRows={5}
              value={formValues.criteria}
              onChange={(event) => handleInputChange('criteria', event.currentTarget.value)}
              required
            />
          </>
        );
      default:
        console.warn(`Unhandled node type: ${currentNode.data.actionTitle}`);
        return null;
    }
  };

  return (
    <Transition appear show={sideBarOpen} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Box
          sx={{
            width: sideBarOpen ? "40%" : "0px",
            height: '99%',
            overflow: "hidden",
            backgroundColor: 'white',
            border: '5px dotted #E5E7EB',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ScrollArea style={{ flex: 1 }}>
            <Box sx={{ padding: '1rem' }}>
              <Text 
                size="xl" 
                weight={500} 
                sx={{ 
                  fontFamily: 'Poppins, sans-serif',
                  marginBottom: '1rem'
                }}
              >
                {currentNode?.data?.label}
              </Text>
              <form onSubmit={(e) => { e.preventDefault(); onFinish(); }}>
                <Stack spacing="md">
                  {renderNodeForm()}
                  <Group position="right" mt="md">
                    <Button type="submit" color="blue">
                      Save
                    </Button>
                  </Group>
                </Stack>
              </form>
            </Box>
          </ScrollArea>
          <Box sx={{ 
            height: '40px', 
            padding: '1rem', 
            borderTop: '1px solid #eaeaea',
            flexShrink: 0,
          }}>
            {currentNode.type !== "startNode" && (
              <Popover width={200} position="top" withArrow shadow="md">
                <Popover.Target>
                  <Button color="red" fullWidth>Delete Step</Button>
                </Popover.Target>
                <Popover.Dropdown>
                  <Text size="sm">Are you sure you want to delete this step?</Text>
                  <Button 
                    color="red" 
                    onClick={handleDeleteNode} 
                    fullWidth 
                    mt="md"
                  >
                    Confirm Delete
                  </Button>
                </Popover.Dropdown>
              </Popover>
            )}
          </Box>
        </Box>
      </Transition.Child>
    </Transition>
  );
};

export default SideBar;