import _ from 'lodash';
import { useState } from 'react';
import { useReactFlow } from 'reactflow';

function useTimerNode() {
    const { setNodes } = useReactFlow();
    const minCount = 1; // Minimum value
    const maxCount = 365; // Maximum value
    const [counter, setCounter] = useState(1);

    const incrementCounter = (suspectedId:string) =>{
        maxCount > counter && setCounter(Number(counter) + 1);
        setNodesImmedietely(counter+1, suspectedId);
    }
    
    let decrementCounter = (suspectedId:string) => {
        minCount < counter && setCounter(Number(counter) - 1);
        setNodesImmedietely(counter-1, suspectedId);
    }

    const handler = (e:any, suspectedId:string) => {
      if (maxCount >= e.target.value) {
        setCounter(Number(e.target.value));
        setNodesImmedietely(Number(e.target.value),suspectedId )
      }
      if (minCount >= e.target.value) {
        setCounter(1);
      } else {
        setCounter(Number(e.target.value));
      }
    };

    const setNodesImmedietely = (value:number, suspectedId:string) => {
        setNodes((nodes) => {
            const clonedNodes = [...nodes];
            const maped = clonedNodes.map((el) => {
                if (el.id === suspectedId) {
                  el.data.days = value;
                }
                return el;
              });
            return maped;
          });
    }


  return {incrementCounter,decrementCounter, handler, counter };
}

export default useTimerNode;
