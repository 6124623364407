import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { Filter } from "./filter";
import { Search } from "./search";
import { AppCard } from "./card";

import styles from "./index.module.css";
import { UUID } from "crypto";

export const Grid = (
  { cardsData }: { cardsData: { title: string; description: string; category: string; app_type: string; acl: string; image_url: string; is_installed: boolean; id: UUID }[] },
) => {
  const [inputValue, setInputValue] = useState("");
  const [activeFilter, setActiveFilter] = useState("");

  //create list of unique categories from cardsData
  const filters: string[] = [
    ...new Set(cardsData.map((el) => el.category)),
  ];

 // const filters: string[] = ["cat1", "cat2", "cat3"];

  return (
    <motion.div>
      <div className={styles.filters}>
        {filters.map((filter, index) => {
          return (
            <Filter
              key={index}
              title={filter}
              isActive={filter === activeFilter}
              onClick={(e: React.MouseEvent) => {
                const el = e.target as HTMLElement;
                el.textContent?.toLowerCase() !== activeFilter
                  ? setActiveFilter(filter)
                  : setActiveFilter("");
              }}
            />
          );
        })}
      </div>
      <Search
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setInputValue(e.target.value);
        }}
      />
      <AnimatePresence >
      <div className={styles.filters}>
        {cardsData
          ?.filter((el) =>
            el.title.toLowerCase().includes(inputValue.toLowerCase()) ||
          el.description.toLowerCase().includes(inputValue.toLowerCase())
          )
          .filter((e) => e.category.includes(activeFilter))
          .map((card, index) => {
            return (
              <AppCard key={index} title={card.title} category={card.category} app_type={card.app_type} acl= {card.acl} description= {card.description} image_url= {card.image_url} is_installed= {card.is_installed} app_id= {card.id} />          );
          })}
          </div>
      </AnimatePresence>
    </motion.div>
  );
};
