import React, { useState, useEffect } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import {
  THEME_COLOR,
  BORDER_WIDTH,
  NODE_WIDTH,
  NODE_HEIGHT,
  CIRCLE_SIZE,
  MAIN_FONT_SIZE,
  SUB_FONT_SIZE,
  TEXT_GRADIENT_CONFIG,
  NODE_BACKGROUND_COLOR,
  NODE_BOX_SHADOW,
  SELECTED_NODE_BG_COLOR,
  SELECTED_NODE_BORDER_COLOR,
  SELECTED_NODE_BOX_SHADOW,
  SELECTED_NODE_BORDER_WIDTH
} from '../../../utility/constants';

const StartNode = ({ id }: { id: string }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { getNode } = useReactFlow();
  const node = getNode(id);
  const isSelected = node?.selected || false;

  useEffect(() => {
    if (isSelected) {
      const style = document.createElement('style');
      style.innerHTML = `
        @keyframes ripple {
          0% {
            box-shadow: 0 0 0 0 rgba(255, 0, 128, 0.2), 0 0 0 5px rgba(255, 0, 128, 0.2), 0 0 0 10px rgba(255, 0, 128, 0.2);
          }
          100% {
            box-shadow: 0 0 0 5px rgba(255, 0, 128, 0.2), 0 0 0 10px rgba(255, 0, 128, 0.2), 0 0 0 15px rgba(255, 0, 128, 0);
          }
        }
      `;
      document.head.appendChild(style);
      return () => {
        document.head.removeChild(style);
      };
    }
  }, [isSelected]);

  const nodeStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: NODE_HEIGHT,
    width: NODE_WIDTH,
    cursor: 'grab',
    transition: 'all 0.3s ease',
  };

  const circleStyle: React.CSSProperties = {
    width: CIRCLE_SIZE,
    height: CIRCLE_SIZE,
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: isHovered ? SELECTED_NODE_BG_COLOR : NODE_BACKGROUND_COLOR,
    boxShadow: isSelected || isHovered ? SELECTED_NODE_BOX_SHADOW : NODE_BOX_SHADOW,
    border: `${isSelected ? SELECTED_NODE_BORDER_WIDTH : BORDER_WIDTH}px solid ${isSelected || isHovered ? SELECTED_NODE_BORDER_COLOR : THEME_COLOR}`,
    transition: 'all 0.3s ease',
    transform: isSelected || isHovered ? "translateY(-2px)" : "none",
    animation: isSelected ? "ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite" : "none",
    zIndex: 10,
    top: '50px',
    position: 'relative',
  };

  const mainLabelStyle = {
    fontWeight: 800,
    fontSize: MAIN_FONT_SIZE,
    lineHeight: 1.2,
    color: '#000',
    marginTop: '3px',

  };

  const subLabelStyle = {
    fontSize: SUB_FONT_SIZE,
    marginTop: '3px',
    background: `linear-gradient(${TEXT_GRADIENT_CONFIG.from}, ${TEXT_GRADIENT_CONFIG.to}, ${TEXT_GRADIENT_CONFIG.deg})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };

  return (
    <div
      style={nodeStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={circleStyle}>
        <div style={mainLabelStyle}>Request</div>
        <div >User Prompt</div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ visibility: 'hidden', bottom: -10 }}
      />
    </div>
  );
};

export default StartNode;